// @ts-check
import React, { Component } from "react";
import { Alert, Snackbar } from "@mui/material";


/**
 * @typedef {object} Props
 * @property {SnackbarDetails} snackbarDetails
 * @property {Boolean} open
 * @extends {Component<Props>}
 */

export class MySnackbar extends Component {

    render() {
        return (
            <Snackbar open={this.props.open} autoHideDuration={this.props.snackbarDetails.autoHideDuration ?? 10000}
                style={{ zIndex: 99999 }} onClose={this.props.snackbarDetails.closeButtonHandler} >
                <Alert style={{fontSize:14}} onClose={this.props.snackbarDetails.closeButtonHandler} severity={this.props.snackbarDetails.severity} sx={{ width: '100%' }} variant="filled">
                    {this.props.snackbarDetails.message}
                </Alert>
            </Snackbar>
        )
    }
}
