// @ts-check
import { callServerAPI, callServerRefreshToken, globalStore, initializeGlobalStates, FormEditMode, getCurrentTimeString, LoggedOnUserTypes } from "./utils";
import React, { Component } from "react";
import { Backdrop, CircularProgress, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import './App.css'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { MyAlertDialog } from "./MyAlertDialog";
import { MySnackbar } from "./MySnackbar";
import ChangePassword from "./ChangePassword";
import Home from "./Home";
import { AppBarFooter } from "./AppBarFooter";
import { ChevronLeft, Class, Group, Style, SupportAgent, Warning, Home as HomeIcon } from "@mui/icons-material";
import LoginUser from "./LoginUser";
import LoginTepngUser from "./LoginTepngUser";
import { GlobalVariablesContext } from "./Context";
import AppBarHeader from "./AppBarHeader";
import CreateEditShippingRequest from "./CreateEditShippingRequest";
import ResetPassword from "./ResetPassword";
import withRouter from "./WithRouter";
import { CreateEditTepngUser } from "./CreateEditTepngUser";
import { CreateEditVendor } from "./CreateEditVendor";
import { CreateEditShippingDocumentType } from "./CreateEditShippingDocumentType";
import { CreateEditShipmentClass } from "./CreateEditShipmentClass";

initializeGlobalStates()


function ProtectedRoute({ children }) {
  const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
  const [loggedOnVendor, ,] = globalStore.useState("loggedOnVendor");
  let location = useLocation();
  console.log(getCurrentTimeString() + ': ProtectedRoute: location = ' + location.pathname + ', loggedOnVendor=' + loggedOnVendor?.email + ', loggedOnTepngUser=' + loggedOnTepngUser?.email)
  if (loggedOnTepngUser == null && loggedOnVendor == null) {
    localStorage.setItem('loginRedirectedFromUrl', location.pathname);
    return <Navigate to="/login-user" replace />
  }
  else
    return children
};

function NoRouteMatch() {
  let location = useLocation();
  return (<div> <h3> No Route match for <code>{location.pathname}</code> </h3> </div>);
}

/**
 * @typedef {object} Props
 * @property {import('react-router-dom').Params<string>} routerParams
 * @property {import('react-router-dom').Location} routerLocation
 * @property {import('react-router-dom').NavigateFunction} routerNavigate
 * @extends {Component<Props>}
 */
class App extends Component {
  appBarHeaderHeight = 50;
  appBarFooterHeight = 50;
  queryParams = new URLSearchParams(window.location.search);

  /** @type { {drawerItem: React.CSSProperties, contentPanel: React.CSSProperties}} */
  styles = {
    contentPanel: {
      width: "100%",
      marginTop: this.appBarHeaderHeight,
      marginBottom: this.appBarFooterHeight,
      paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 0,
      transition: "2.5s",
      transitionTimingFunction: "ease-in",
      alignItems: "center",
      alignContent: "top"
    },
    drawerItem: {
      height: this.appBarHeaderHeight,
    }
  }

  theme = createTheme({
    palette: {
      primary: { main: "#222222", contrastText: "#ffffff" },
      secondary: { main: "#0288D1", contrastText: "#ffffff" },
      error: { main: "#ff0000", contrastText: "#ffffff" },
      info: { main: "#0288D1", contrastText: "#ffffff" },
      action: { selected: "#ff0000", focus: "#ff0000" },
      background: { default: "#ffffff" },
      text: { primary: "#222222", secondary: "#0288D1", disabled: "#777777", },


    },
    typography: {
      htmlFontSize: 16,
      fontSize: 12,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    }
  });

  constructor(props) {
    super(props);
    console.log(getCurrentTimeString() + ': App.js contructor called')
    //create variables to hold bind functions
    this.handleToggleDrawer = this.handleToggleDrawer.bind(this)
    this.handleGlobalActionChange = this.handleGlobalActionChange.bind(this)
    this.setHeader = this.setHeader.bind(this)
    this.showAlertDialog = this.showAlertDialog.bind(this)
    this.showSnackbar = this.showSnackbar.bind(this)
    this.showBackdrop = this.showBackdrop.bind(this)
    this.hideBackdrop = this.hideBackdrop.bind(this)
    this.alertOkButtonHandler = this.alertOkButtonHandler.bind(this)
    this.alertCancelButtonHandler = this.alertCancelButtonHandler.bind(this)
    this.snackbarCloseButtonHandler = this.snackbarCloseButtonHandler.bind(this)
    //set initial state
    /** @type { {header:string, loggedOnUserType:number|null, loggedOnVendor:Vendor|null, loggedOnTepngUser:TepngUser|null, serverGlobalSettings:ServerGlobalSettings|null, shipmentClasses:ShipmentClass[], shippingDocumentTypes:ShippingDocumentType[], drawerOpen:Boolean, alertDialogDetails:AlertDialogDetails,alertDialogOpen:Boolean,snackbarDetails:SnackbarDetails,snackbarOpen:Boolean,backdropOpen:Boolean,backdropMessage:string, callServerRefreshTokenCompleted:boolean} } */
    this.state = {
      header: "",
      loggedOnUserType: null,
      loggedOnVendor: null,
      loggedOnTepngUser: null,
      serverGlobalSettings: null,
      shipmentClasses: [],
      shippingDocumentTypes: [],
      drawerOpen: false,
      alertDialogDetails: { title: '', message: <span></span> },
      alertDialogOpen: false,
      snackbarDetails: { message: '', autoHideDuration: 6000, severity: 'info' },
      snackbarOpen: false,
      backdropOpen: false,
      backdropMessage: '',
      callServerRefreshTokenCompleted: false
    }
  }

  componentDidMount() {
    // Subscribe to global store changes
    let self = this
    globalStore.getState("loggedOnUserType").subscribe(function (value) {
      self.setState({ loggedOnUserType: value })
    })
    globalStore.getState("loggedOnVendor").subscribe(function (value) {
      self.setState({ loggedOnVendor: value })
    })
    globalStore.getState("loggedOnTepngUser").subscribe(function (value) {
      self.setState({ loggedOnTepngUser: value })
    })
    // load initial data
    this.getGlobalAppSettings()
    this.getShipmentClasses()
    this.getShippingDocumentTypes()
    // refresh user login
    callServerRefreshToken(false)
      .then(function (/** @type boolean */ response) {
        self.setState({ callServerRefreshTokenCompleted: true })
      })
  }

  getGlobalAppSettings() {
    var self = this;
    self.showBackdrop('Retrieving Global Settings')
    callServerAPI('GET', '/api/GlobalSettings', null, false)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ServerGlobalSettings>> } */ response) {
        self.setState({ serverGlobalSettings: response.data.returnData });
        self.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ServerGlobalSettings>> } */response) {
        self.hideBackdrop()
        self.showAlertDialog({ title: "Error", message: <span>{response.data.message ?? response.statusText + ' - ' + response.data}</span>, icon: <Warning fontSize="large" color="error" /> })
      })
  }

  getShipmentClasses() {
    var self = this;
    self.showBackdrop('Retrieving Shipment Classes')
    callServerAPI('GET', '/api/ShipmentClass', null, false)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShipmentClass[]>> } */ response) {
        self.setState({ shipmentClasses: response.data.returnData });
        self.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShipmentClass[]>> } */response) {
        self.hideBackdrop()
        self.showAlertDialog({ title: "Error", message: <span>{response.data.message ?? response.statusText + ' - ' + response.data}</span>, icon: <Warning fontSize="large" color="error" /> })
      })
  }

  getShippingDocumentTypes() {
    var self = this;
    self.showBackdrop('Retrieving Shipping Document Types')
    callServerAPI('GET', '/api/DocumentType', null, false)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocumentType[]>> } */ response) {
        self.setState({ shippingDocumentTypes: response.data.returnData });
        self.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocumentType[]>> } */response) {
        self.hideBackdrop()
        self.showAlertDialog({ title: "Error", message: <span>{response.data.message ?? response.statusText + ' - ' + response.data}</span>, icon: <Warning fontSize="large" color="error" /> })
      })
  }

  handleMenuClick(event, value) {
    this.setState({ selectedGlobalAction: value, drawerOpen: false })
  }

  handleGlobalActionChange(action) {
    this.setState({ selectedGlobalAction: action, drawerOpen: false })
  }

  /** @param {string} header   */
  setHeader(header) {
    this.setState({ header: header })
  }

  /** @param {string} backdropMessage   */
  showBackdrop(backdropMessage) {
    this.setState({ backdropMessage: backdropMessage, backdropOpen: true })
  }

  hideBackdrop() {
    this.setState({ backdropOpen: false })
  }

  /** @param {SnackbarDetails} paramSnackbarDetails   */
  showSnackbar(paramSnackbarDetails) {
    this.setState({ snackbarDetails: paramSnackbarDetails, snackbarOpen: true })
  }

  /** @param  {React.SyntheticEvent<any| Event>} event, @param {string} reason */
  snackbarCloseButtonHandler(event, reason) {
    if (reason === 'clickaway') return;
    this.setState({ snackbarOpen: false })
    if (this.state.snackbarDetails.closeButtonHandler != null)
      this.state.snackbarDetails.closeButtonHandler(event, reason)
  }

  /** @param {AlertDialogDetails} paramAlertDialogDetails   */
  showAlertDialog(paramAlertDialogDetails) {
    this.setState({ alertDialogDetails: paramAlertDialogDetails, alertDialogOpen: true })
  }

  alertCancelButtonHandler(event) {
    this.setState({ alertDialogOpen: false })
    if (this.state.alertDialogDetails.cancelButtonHandler != null)
      this.state.alertDialogDetails.cancelButtonHandler(event)
  }

  alertOkButtonHandler(event) {
    this.setState({ alertDialogOpen: false })
    if (this.state.alertDialogDetails.okButtonHandler != null)
      this.state.alertDialogDetails.okButtonHandler(event)
  }

  handleToggleDrawer(event) {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <GlobalVariablesContext.Provider value={{ setHeader: this.setHeader, showAlert: this.showAlertDialog, showSnackbar: this.showSnackbar, showBackdrop: this.showBackdrop, hideBackdrop: this.hideBackdrop }}>
          <>
            {this.state.callServerRefreshTokenCompleted && this.state.serverGlobalSettings &&
              <>
                <AppBarHeader header={this.state.header} appBarHeight={this.appBarHeaderHeight} loggedOnUserType={this.state.loggedOnUserType} loggedOnVendor={this.state.loggedOnVendor} loggedOnTepngUser={this.state.loggedOnTepngUser} onToggleDrawer={this.handleToggleDrawer} />
                <Grid id="AppDetailsContainer" item xs={12} style={this.styles.contentPanel}>
                  <div>
                    <Routes>
                      <Route path='/' element={
                        <ProtectedRoute>
                          {this.state.serverGlobalSettings && this.state.shipmentClasses && this.state.shippingDocumentTypes && this.state.loggedOnUserType &&
                            <Home loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnVendor={this.state.loggedOnVendor} loggedOnUserType={this.state.loggedOnUserType}
                              shipmentClasses={this.state.shipmentClasses} shippingDocumentTypes={this.state.shippingDocumentTypes} />
                          }
                        </ProtectedRoute>
                      } />
                      <Route path='/login-user' element={<LoginUser />} />
                      <Route path='/login-tepnguser' element={<LoginTepngUser />} />
                      <Route path='/change-password' element={
                        <ProtectedRoute>
                          <ChangePassword loggedOnVendor={this.state.loggedOnVendor} loggedOnUserType={this.state.loggedOnUserType} />
                        </ProtectedRoute>
                      } />
                      <Route path='/reset-password' element={<ResetPassword />} />
                      <Route path='/view-shippingrequest/:id' element={
                        <ProtectedRoute>
                          <CreateEditShippingRequest formEditMode={FormEditMode.VIEW} loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnVendor={this.state.loggedOnVendor} loggedOnUserType={this.state.loggedOnUserType} shipmentClasses={this.state.shipmentClasses} shippingDocumentTypes={this.state.shippingDocumentTypes} />
                        </ProtectedRoute>
                      } />
                      <Route path='/edit-shippingrequest/:id' element={
                        <ProtectedRoute>
                          <CreateEditShippingRequest formEditMode={FormEditMode.EDIT} loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnVendor={this.state.loggedOnVendor} loggedOnUserType={this.state.loggedOnUserType} shipmentClasses={this.state.shipmentClasses} shippingDocumentTypes={this.state.shippingDocumentTypes} />
                        </ProtectedRoute>
                      } />
                      <Route path='/create-shippingrequest' element={
                        <ProtectedRoute>
                          <CreateEditShippingRequest formEditMode={FormEditMode.CREATE} loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnVendor={this.state.loggedOnVendor} loggedOnUserType={this.state.loggedOnUserType} shipmentClasses={this.state.shipmentClasses} shippingDocumentTypes={this.state.shippingDocumentTypes} />
                        </ProtectedRoute>
                      } />
                      <Route path='/admin-tepngusers' element={
                        <ProtectedRoute>
                          {this.state.loggedOnTepngUser && this.state.loggedOnUserType &&
                            <CreateEditTepngUser loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnUserType={this.state.loggedOnUserType} />}
                        </ProtectedRoute>
                      } />
                      <Route path='/admin-vendors' element={
                        <ProtectedRoute>
                          {this.state.loggedOnTepngUser && this.state.loggedOnUserType &&
                            <CreateEditVendor loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnUserType={this.state.loggedOnUserType} />}
                        </ProtectedRoute>
                      } />
                      <Route path='/admin-shipmentclasses' element={
                        <ProtectedRoute>
                          {this.state.loggedOnTepngUser && this.state.loggedOnUserType && this.state.shippingDocumentTypes &&
                            <CreateEditShipmentClass loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnUserType={this.state.loggedOnUserType} shippingDocumentTypes={this.state.shippingDocumentTypes} />}
                        </ProtectedRoute>
                      } />
                      <Route path='/admin-documenttypes' element={
                        <ProtectedRoute>
                          {this.state.loggedOnTepngUser && this.state.loggedOnUserType &&
                            <CreateEditShippingDocumentType loggedOnTepngUser={this.state.loggedOnTepngUser} loggedOnUserType={this.state.loggedOnUserType} />}
                        </ProtectedRoute>
                      } />
                      <Route path="*" element={<NoRouteMatch />} />
                    </Routes>
                  </div>
                </Grid>
                <Drawer variant="temporary" anchor={"left"} open={this.state.drawerOpen} onClose={this.handleToggleDrawer} >
                  <div style={this.styles.drawerItem}>
                    <IconButton onClick={this.handleToggleDrawer}> <ChevronLeft color="info" /> </IconButton>
                  </div>
                  <Divider />
                  <List style={{ padding: 0 }}>
                    <ListItem style={this.styles.drawerItem} divider={true} button classes={{ selected: "SelectedMenu" }}
                      selected={this.props.routerLocation.pathname === "/"} onClick={() => { this.setState({ drawerOpen: false }); this.props.routerNavigate("/") }}>
                      <ListItemIcon sx={{ color: this.props.routerLocation.pathname === "/" ? "red" : "#000000" }}> <HomeIcon fontSize="large" /> </ListItemIcon>
                      <ListItemText primary='Home' />
                    </ListItem>
                    <ListItem disabled={!(this.state.loggedOnUserType === LoggedOnUserTypes.TepngUser && this.state.loggedOnTepngUser?.isAdmin)} style={this.styles.drawerItem} divider={true} button classes={{ selected: "SelectedMenu" }}
                      selected={this.props.routerLocation.pathname === "/admin-vendors"} onClick={() => { this.setState({ drawerOpen: false }); this.props.routerNavigate("/admin-vendors") }}>
                      <ListItemIcon sx={{ color: this.props.routerLocation.pathname === "/admin-vendors" ? "red" : "#000000" }}> <SupportAgent fontSize="large" /> </ListItemIcon>
                      <ListItemText primary='Manage Vendors' />
                    </ListItem>
                    <ListItem disabled={!(this.state.loggedOnUserType === LoggedOnUserTypes.TepngUser && this.state.loggedOnTepngUser?.isAdmin)} style={this.styles.drawerItem} divider={true} button classes={{ selected: "SelectedMenu" }}
                      selected={this.props.routerLocation.pathname === "/admin-tepngusers"} onClick={() => { this.setState({ drawerOpen: false }); this.props.routerNavigate("/admin-tepngusers") }}>
                      <ListItemIcon sx={{ color: this.props.routerLocation.pathname === "/admin-tepngusers" ? "red" : "#000000" }}> <Group fontSize="large" /> </ListItemIcon>
                      <ListItemText primary='Manage TotalEnergies Users' />
                    </ListItem>
                    <ListItem disabled={!(this.state.loggedOnUserType === LoggedOnUserTypes.TepngUser && this.state.loggedOnTepngUser?.isAdmin)} style={this.styles.drawerItem} divider={true} button classes={{ selected: "SelectedMenu" }}
                      selected={this.props.routerLocation.pathname === "/admin-shipmentclasses"} onClick={() => { this.setState({ drawerOpen: false }); this.props.routerNavigate("/admin-shipmentclasses") }}>
                      <ListItemIcon sx={{ color: this.props.routerLocation.pathname === "/admin-shipmentclasses" ? "red" : "#000000" }}> <Class fontSize="large" /> </ListItemIcon>
                      <ListItemText primary='Manage Shipment Classes' />
                    </ListItem>
                    <ListItem disabled={!(this.state.loggedOnUserType === LoggedOnUserTypes.TepngUser && this.state.loggedOnTepngUser?.isAdmin)} style={this.styles.drawerItem} divider={true} button classes={{ selected: "SelectedMenu" }}
                      selected={this.props.routerLocation.pathname === "/admin-documenttypes"} onClick={() => { this.setState({ drawerOpen: false }); this.props.routerNavigate("/admin-documenttypes") }}>
                      <ListItemIcon sx={{ color: this.props.routerLocation.pathname === "/admin-documenttypes" ? "red" : "#000000" }}> <Style fontSize="large" /> </ListItemIcon>
                      <ListItemText primary='Manage Document Types' />
                    </ListItem>
                  </List>
                </Drawer>
                <AppBarFooter appBarHeight={this.appBarFooterHeight} serverGlobalSettings={this.state.serverGlobalSettings} />
              </>
            }
          </>

          <MyAlertDialog open={this.state.alertDialogOpen} alertDialogDetails={{ ...this.state.alertDialogDetails, okButtonHandler: this.alertOkButtonHandler, cancelButtonHandler: this.alertCancelButtonHandler }} />
          <MySnackbar open={this.state.snackbarOpen} snackbarDetails={{ ...this.state.snackbarDetails, closeButtonHandler: this.snackbarCloseButtonHandler }} />
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.backdropOpen} >
            <CircularProgress color="inherit" />
            <Typography variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.backdropMessage}</Typography>
          </Backdrop>
        </GlobalVariablesContext.Provider>
      </ThemeProvider >
    )
  }
}

export default withRouter(App);
