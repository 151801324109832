// @ts-check
import { callServerAPI, LoggedOnUserTypes } from "./utils";
import React, { Component } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import MaterialTable from '@material-table/core'
import { GlobalVariablesContext } from './Context'

/**
 * @typedef {object} Props
 * @property {TepngUser} loggedOnTepngUser
 * @property {LoggedOnUserTypes} loggedOnUserType
 * @extends {Component<Props>}
 */

export class CreateEditShippingDocumentType extends Component {
    /** @type { React.Context<GlobalVariables>} */
    static contextType = GlobalVariablesContext;
    /** @type React.ContextType < typeof GlobalVariablesContext >*/
    context = this.context

    constructor(props) {
        super(props);
        //declare variables to hold all function binding to class instance
        this.loadShippingDocumentTypes = this.loadShippingDocumentTypes.bind(this)
        //set initial state
        /** @type { {shippingDocumentTypes:ShippingDocumentType[]} } */
        this.state = {
            shippingDocumentTypes: []
        }
    }

    componentDidMount() {
        this.context.setHeader("Manage Shipping Document Types")
        this.loadShippingDocumentTypes()
    }


    loadShippingDocumentTypes() {
        var self = this;
        self.context.showBackdrop('Loading Shipping Document Types')
        callServerAPI('GET', '/api/DocumentType', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocumentType[]>> } */ response) {
                self.setState({ shippingDocumentTypes: response.data.returnData, });
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @param {ShippingDocumentType} newData, @param {any} resolve, @param {any} reject */
    addRecord(newData, resolve, reject) {
        var self = this;
        self.context.showBackdrop('Creating New Shipping Document Type ...')
        callServerAPI('POST', '/api/DocumentType', newData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocumentType>> } */ response) {
                // the 1st argument in setState is either a state object (property:value) or an updater function that accepts the previous state and returns a new state object
                //it is neccessary to use the updater function approach when you want to update a state object based on its previous state
                //the returned state object is merged with the existing state. we use updater function below as we want to change an element in an array in previous state object
                self.setState(state => { return { shippingDocumentTypes: [...state.shippingDocumentTypes, response.data.returnData] } })
                resolve()
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
                reject()
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @param {ShippingDocumentType} newData, @param {ShippingDocumentType|undefined} oldData, @param {any} resolve, @param {any} reject */
    updateRecord(newData, oldData, resolve, reject) {
        if (oldData == null) return
        var self = this;
        self.context.showBackdrop('Updating Shipping Document Type ...')
        callServerAPI('PUT', `/api/DocumentType/${oldData.id}`, newData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocumentType>> } */ response) {
                // the 1st argument in setState is either a state object (property:value) or an updater function that accepts the previous state and returns a new state object
                //it is neccessary to use the updater function approach when you want to update a state object based on its previous state
                //the returned state object is merged with the existing state. we use updater function below as we want to change an element in an array in previous state object
                self.setState(state => {
                    return { shippingDocumentTypes: self.state.shippingDocumentTypes.map(inst => inst.id === oldData.id ? response.data.returnData : inst) }
                })
                resolve()
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
                reject()
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @param {ShippingDocumentType} oldData, @param {any} resolve, @param {any} reject */
    deleteRecord(oldData, resolve, reject) {
        var self = this;
        self.context.showBackdrop('Deleting Shipping Document Type ...')
        callServerAPI('DELETE', `/api/DocumentType/${oldData.id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                // the 1st argument in setState is either a state object (property:value) or an updater function that accepts the previous state and returns a new state object
                //it is neccessary to use the updater function approach when you want to update a state object based on its previous state
                //the returned state object is merged with the existing state. we use updater function below as we want to remove an element in an array in previous state object
                self.setState(state => {
                    return { shippingDocumentTypes: self.state.shippingDocumentTypes.filter(inst => inst.id !== oldData.id) }
                })
                resolve()
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
                reject()
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    render() {
        if (this.props.loggedOnUserType !== LoggedOnUserTypes.TepngUser || !this.props.loggedOnTepngUser?.isAdmin)
            return <Typography textAlign='center' variant="h6" color='primary'>This page is restricted to Shipping Documents Tracker Admins</Typography>
        else
            return (
                /* material-table had some issues so I decided to use @material-table/core a fork of material-table
                   i also used the npm install @material-table/core@next to install the latest (pre-release) version compatible with mui version 5
                   issue with non unique id rows warning messages: https://github.com/material-table-core/core/issues/487*/
                <GlobalVariablesContext.Consumer>
                    {(globalVariablesValues) => globalVariablesValues &&
                        <div style={{ padding: 10 }}>
                            <div className='HorizontalOverFlowContainer'>
                                <Paper className="Form" style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <div style={{ width: '100%', overflowY: 'auto', height: 'calc(100vh - 150px)' }}>
                                        <Grid id="UserProfilesViewContainer" container style={{ padding: 5 }} >
                                            <Grid item xs={12}>
                                                {this.state.shippingDocumentTypes && this.state.shippingDocumentTypes.length > 0 &&
                                                    <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '100%' }} elevation={0} /> }}
                                                        columns={[
                                                            { title: 'Name', field: 'name', align: 'left', validate: rowData => rowData.name ? true : 'Name is required' },
                                                            { title: 'Document Id Reference', field: 'documentReferenceIdType', align: 'left' },
                                                        ]}
                                                        data={this.state.shippingDocumentTypes}
                                                        editable={{
                                                            onRowAdd: (newData) => new Promise((resolve, reject) => { this.addRecord(newData, resolve, reject) }),
                                                            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => { this.updateRecord(newData, oldData, resolve, reject) }),
                                                            onRowDelete: (oldData) => new Promise((resolve, reject) => { this.deleteRecord(oldData, resolve, reject) }),
                                                        }}

                                                        localization={{ header: { actions: '' } }}

                                                        options={{
                                                            idSynonym: 'id', //https://github.com/material-table-core/core/issues/487 to prevent warning on console
                                                            pageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false,
                                                            rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" },
                                                            editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'", color: '#0288D1' },
                                                            actionsCellStyle: { color: '#0288D1' }, headerStyle: { color: '#0288D1' },
                                                        }}
                                                    />}
                                            </Grid>
                                        </Grid>


                                    </div>
                                    <br />

                                </Paper>
                            </div>
                        </div>
                    }
                </GlobalVariablesContext.Consumer>
            )
    }
}
