import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

let rootElement = document.getElementById('root')
if (rootElement != null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <React.StrictMode> https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
    <Router>
      <App />
    </Router>
    // </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
