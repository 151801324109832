import './App.css';
import React, { Component } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography, Link as ButtonLink } from '@mui/material';
import { GlobalVariablesContext } from './Context';
import { Link } from 'react-router-dom';
import { Login } from '@mui/icons-material';
import { callServerAPI, callServerVendorLogin, callServerVendorPreLogin2FA } from './utils';
import withRouter from './WithRouter';
import MediaQuery from 'react-responsive';

/**
 * @typedef {object} Props
 * @property {import('react-router-dom').Params<string>} routerParams
 * @property {import('react-router-dom').Location} routerLocation
 * @property {import('react-router-dom').NavigateFunction} routerNavigate
 * @extends {Component<Props>}
 */
class LoginUser extends Component {
  /** @type { React.Context<GlobalVariables>} */
  static contextType = GlobalVariablesContext;
  /** @type React.ContextType < typeof GlobalVariablesContext >*/
  context = this.context

  constructor(props) {
    super(props);
    //declare variables to hold all function binding to class instance
    this.vendorPreLogin2FA = this.vendorPreLogin2FA.bind(this)
    this.vendorLogin = this.vendorLogin.bind(this)
    this.sendResetPasswordLink = this.sendResetPasswordLink.bind(this)
    this.updateFormValue = this.updateFormValue.bind(this)
    this.updateFormNumericValue = this.updateFormNumericValue.bind(this)
    this.updateCheckBoxValue = this.updateCheckBoxValue.bind(this)
    this.onOtpCodeKeyDown = this.onOtpCodeKeyDown.bind(this)
    //set initial state
    /** @type { { email:string, password:string, awaitingOtpCodeEntry:boolean, rememberMe:boolean}} */
    this.state = {
      email: '',
      password: '',
      awaitingOtpCodeEntry: false,
      rememberMe: false
    }
  }

  componentDidMount() {

    this.context.setHeader("Login")
  }

  vendorPreLogin2FA() {
    var self = this;
    self.context.showBackdrop('Vendor Login ...')
    callServerVendorPreLogin2FA({ email: this.state.email, password: this.state.password, rememberMe: this.state.rememberMe })
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        self.setState({ awaitingOtpCodeEntry: true });
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'info' })

      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        //self.context.showAlert({ title: "Error", message: <span>{response.data.message ?? response.statusText + ' - ' + response.data}</span>, icon: <Warning fontSize="large" color="error" /> })
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  vendorLogin(otpCode) {
    var self = this;
    self.context.showBackdrop('Vendor Login ...')
    callServerVendorLogin({ email: this.state.email, password: this.state.password, rememberMe: this.state.rememberMe, loginOTPCode: otpCode })
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: 'Vendor Login Successful', severity: 'success' })
        const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
        localStorage.removeItem('loginRedirectedFromUrl');
        self.props.routerNavigate(loginRedirectedFromUrl ?? "/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        //self.context.showAlert({ title: "Error", message: <span>{response.data.message ?? response.statusText + ' - ' + response.data}</span>, icon: <Warning fontSize="large" color="error" /> })
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  sendResetPasswordLink() {
    if (!this.state.email) {
      this.context.showSnackbar({ message: 'Please enter your email to reset your password', severity: 'error' })
      return
    }
    var self = this;
    /** @type SendResetPasswordTokenDto */ let data = { email: this.state.email, resetPasswordLink: window.location.origin + "/reset-password" }
    self.context.showBackdrop('Sending Reset Password Link to ' + this.state.email + ' ...')
    callServerAPI('POST', '/api/AuthenticateVendor/SendResetPasswordToken', data, false)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'info' })
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  updateFormValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    this.setState({ [eventTargetName]: eventTargetValue })
  }

  updateFormNumericValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    this.setState({ [eventTargetName]: Number(eventTargetValue) })
  }

  updateCheckBoxValue(event) {
    let eventTargetName = event.target.name; let eventTargetChecked = event.target.checked;
    this.setState({ [eventTargetName]: eventTargetChecked })
  }

  onOtpCodeKeyDown(event) {
    let n = (window.Event) ? event.which : event.keyCode;
    let otpCode = ''
    if (n === 8 || n === 46 || n === 37 || n === 39) return  // del, backspace, left arrow, right arrow
    if (n >= 48 && n <= 57) // check if valid number or enter key was pressed
      otpCode = event.target.value + event.key
    else if (n === 13)
      otpCode = event.target.value
    else {
      event.preventDefault()
      return
    }

    if (otpCode.length === 6)
      this.vendorLogin(otpCode)
  }

  render() {
    return (
      <GlobalVariablesContext.Consumer>
        {(globalVariablesValues) => globalVariablesValues &&
          <>
            <Grid id="VendorLoginContainer" container spacing={0}  >
              <MediaQuery  minWidth={1224}>
                <Grid item md={8} sm={0} xs={0} display='flex' alignItems='center'>
                  <img src='/logo.png' width='80%' alt="logo.png" style={{ display: 'block', margin: 'auto' }} />
                </Grid>
              </MediaQuery>
              <Grid item md={4} sm={12} xs={12}>
                <Paper className="Form" style={{ width: '100%', height: 'calc(100vh - 150px)' }}>
                  <Grid id="LoginContainer" container spacing={2} className="FormDetails" alignContent='center' >
                    <Grid item xs={12}>
                      <Typography color="textSecondary" variant='h3' fontWeight={700} >Sign in </Typography>
                    </Grid>
                    {!this.state.awaitingOtpCodeEntry &&
                      <>
                        <Grid item xs={12}>
                          <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Email </Typography>
                          <TextField fullWidth required name="email" type="email" variant="outlined" size="medium" value={this.state.email} onChange={this.updateFormValue}
                            placeholder="Enter your registered email" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Password </Typography>
                          <TextField fullWidth required name="password" type="password" variant="outlined" size="medium" value={this.state.password} onChange={this.updateFormValue}
                            placeholder="Enter your password" />
                          <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} size="medium" name="rememberMe" checked={this.state.rememberMe} onChange={this.updateCheckBoxValue} />} label="Remember Me" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="textSecondary" > TotalEnergies User? <Link to='/login-tepnguser' style={{ color: 'red' }}> Click here to login   </Link> </Typography>
                          <Typography color="textSecondary" > New User / Forgot Password? <ButtonLink color="error" style={{ cursor: 'pointer' }} onClick={this.sendResetPasswordLink}> Click here to reset password</ButtonLink> </Typography>
                        </Grid>
                        <Grid item xs={12} className="FormHeaderOrFooterDetails">
                          <Button fullWidth disabled={!this.state.email || !this.state.password} variant="contained" size="large" color="secondary" onClick={this.vendorPreLogin2FA} ><Login /> &nbsp;Login</Button>
                        </Grid>
                      </>
                    }

                    {this.state.awaitingOtpCodeEntry &&

                      <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Enter OTP Code: </Typography>
                        <TextField fullWidth autoComplete="off" variant="outlined" size="medium" defaultValue='' onKeyDown={this.onOtpCodeKeyDown} inputProps={{ style: { color: 'red', fontSize: 24, letterSpacing: 10 }, maxLength: 6 }} />
                      </Grid>
                    }

                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        }
      </GlobalVariablesContext.Consumer>
    )
  }
}

export default withRouter(LoginUser);