// @ts-check
import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from "@mui/material";

/**
 * @typedef {object} Props
 * @property {AlertDialogDetails} alertDialogDetails
 * @property {Boolean} open
 * @extends {Component<Props>}
 */

export class MyAlertDialog extends Component {

    render() {
        return (
            <Dialog open={this.props.open} keepMounted>
                <DialogTitle > <Stack direction='row'>{this.props.alertDialogDetails.icon} &nbsp;&nbsp;&nbsp;&nbsp; {this.props.alertDialogDetails.title} </Stack></DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ whiteSpace: 'pre-line' }}> {this.props.alertDialogDetails.message} </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.props.alertDialogDetails.okButtonDisplayed === true && <Button onClick={this.props.alertDialogDetails.okButtonHandler} color="secondary">{this.props.alertDialogDetails.okButtonText == null ? 'Yes' : this.props.alertDialogDetails.okButtonText} </Button>}
                    <Button onClick={this.props.alertDialogDetails.cancelButtonHandler} color="info">{this.props.alertDialogDetails.cancelButtonText == null ? (this.props.alertDialogDetails.okButtonDisplayed ? 'No' : 'Close') : this.props.alertDialogDetails.cancelButtonText} </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
