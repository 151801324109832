import './App.css';
import React, { Component } from "react";
import { GlobalVariablesContext } from './Context';
import { Warning } from '@mui/icons-material';
import { callServerTepngUserLogin, ServerApiReturnCodes, SERVER_URL } from './utils';
import withRouter from './WithRouter';

/**
 * @typedef {object} Props
 * @property {import('react-router-dom').Params<string>} routerParams
 * @property {import('react-router-dom').Location} routerLocation
 * @property {import('react-router-dom').NavigateFunction} routerNavigate
 * @extends {Component<Props>}
 */
class LoginTepngUser extends Component {
  /** @type { React.Context<GlobalVariables>} */
  static contextType = GlobalVariablesContext;
  /** @type React.ContextType < typeof GlobalVariablesContext >*/
  context = this.context

  componentDidMount() {
    //login and load initial data
    this.loginTepngUser()
  }

  loginTepngUser() {
    var self = this;
    self.context.showBackdrop('TotalEnergies User Login ...')
    callServerTepngUserLogin()
      .then(function (response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: 'TotalEnergies User Login Successful', severity: 'success' })
        const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
        localStorage.removeItem('loginRedirectedFromUrl');
        self.props.routerNavigate(loginRedirectedFromUrl ?? "/")
      })
      .catch(function (/** @type import('axios').AxiosResponse<ServerAPIResponse<string>, any> */ error) {
        self.context.hideBackdrop()
        let queryParams = new URLSearchParams(window.location.search);
        // micrsoft login redirect - the frond end url with the /login-tepnguser needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect

        if (queryParams.get("azureauthredirect") !== '1' && error.data.returnCode === ServerApiReturnCodes.Unauthorized) {
          let url = new URL(window.location.origin + '/login-tepnguser')
          url.searchParams.append('azureauthredirect', '1')
          window.location.replace(SERVER_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));
        }
        else
          self.context.showAlert({ title: "Error", message: <span>{error.data.message ?? error.statusText + ' - ' + error.data}</span>, icon: <Warning fontSize="large" color="error" /> })
      })
  }


  render() {
    return <></>
  }
}

export default withRouter(LoginTepngUser);
