// @ts-check
import { callServerAPI, LoggedOnUserTypes } from "./utils";
import React, { Component } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import MaterialTable from '@material-table/core'
import { GlobalVariablesContext } from './Context'
import { LockPerson, ManageAccounts } from "@mui/icons-material";

/**
 * @typedef {object} Props
 * @property {TepngUser} loggedOnTepngUser
 * @property {LoggedOnUserTypes} loggedOnUserType
 * @extends {Component<Props>}
 */

export class CreateEditVendor extends Component {
    /** @type { React.Context<GlobalVariables>} */
    static contextType = GlobalVariablesContext;
    /** @type React.ContextType < typeof GlobalVariablesContext >*/
    context = this.context

    constructor(props) {
        super(props);
        //declare variables to hold all function binding to class instance
        this.loadVendors = this.loadVendors.bind(this)
        this.disableAccount = this.disableAccount.bind(this)
        this.enableAccount = this.enableAccount.bind(this)
        //set initial state
        /** @type { {vendors:Vendor[]} } */
        this.state = {
            vendors: []
        }
    }

    componentDidMount() {
        this.context.setHeader("Manage Agents")
        //load UserProfiles
        this.loadVendors()
    }


    loadVendors() {
        var self = this;
        self.context.showBackdrop('Loading Agent Profiles')
        callServerAPI('GET', '/api/Vendor', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Vendor[]>> } */ response) {
                self.setState({ vendors: response.data.returnData, });
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @param {Vendor} newData, @param {any} resolve, @param {any} reject */
    addRecord(newData, resolve, reject) {
        var self = this;
        self.context.showBackdrop('Creating New Agent Profile ...')
        callServerAPI('POST', '/api/Vendor', newData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Vendor>> } */ response) {
                // the 1st argument in setState is either a state object (property:value) or an updater function that accepts the previous state and returns a new state object
                //it is neccessary to use the updater function approach when you want to update a state object based on its previous state
                //the returned state object is merged with the existing state. we use updater function below as we want to change an element in an array in previous state object
                self.setState(state => { return { vendors: [...state.vendors, response.data.returnData] } })
                resolve()
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
                reject()
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @param {Vendor} newData, @param {Vendor|undefined} oldData, @param {any} resolve, @param {any} reject */
    updateRecord(newData, oldData, resolve, reject) {
        if (oldData == null) return
        var self = this;
        self.context.showBackdrop('Updating Agent Profile ...')
        callServerAPI('PUT', `/api/Vendor/${oldData.id}`, newData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Vendor>> } */ response) {
                // the 1st argument in setState is either a state object (property:value) or an updater function that accepts the previous state and returns a new state object
                //it is neccessary to use the updater function approach when you want to update a state object based on its previous state
                //the returned state object is merged with the existing state. we use updater function below as we want to change an element in an array in previous state object
                self.setState(state => {
                    return { vendors: self.state.vendors.map(inst => inst.id === oldData.id ? response.data.returnData : inst) }
                })
                resolve()
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
                reject()
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @param {Vendor} oldData, @param {any} resolve, @param {any} reject */
    deleteRecord(oldData, resolve, reject) {
        var self = this;
        self.context.showBackdrop('Deleting Agent Profile ...')
        callServerAPI('DELETE', `/api/Vendor/${oldData.id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                // the 1st argument in setState is either a state object (property:value) or an updater function that accepts the previous state and returns a new state object
                //it is neccessary to use the updater function approach when you want to update a state object based on its previous state
                //the returned state object is merged with the existing state. we use updater function below as we want to remove an element in an array in previous state object
                self.setState(state => {
                    return { vendors: self.state.vendors.filter(inst => inst.id !== oldData.id) }
                })
                resolve()
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
                reject()
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @type {function(any, Vendor): void} Closure syntax */
    disableAccount(event, oldData) {
        event.persist()
        var self = this;
        self.context.showBackdrop('Disabling Agent Profile ...')
        callServerAPI('PATCH', `/api/Vendor/DisableAccount/${oldData.id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                self.setState(state => {
                    return { vendors: self.state.vendors.map(inst => inst.id === oldData.id ? { ...oldData, accountDisabled: true } : inst) }
                })
                self.context.showSnackbar({ message: response.data.message, severity: 'success' })
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    /** @type {function(any, Vendor): void} Closure syntax */
    enableAccount(event, oldData) {
        var self = this;
        self.context.showBackdrop('Enabling Agent Profile ...')
        callServerAPI('PATCH', `/api/Vendor/EnableAccount/${oldData.id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                self.setState(state => {
                    return { vendors: self.state.vendors.map(inst => inst.id === oldData.id ? { ...oldData, accountDisabled: false } : inst) }
                })
                self.context.showSnackbar({ message: response.data.message, severity: 'success' })
            })
            .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
                self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
            })
            .finally(function () {
                self.context.hideBackdrop()
            });
    }

    render() {
        if (this.props.loggedOnUserType !== LoggedOnUserTypes.TepngUser || !this.props.loggedOnTepngUser?.isAdmin)
            return <Typography textAlign='center' variant="h6" color='primary'>This page is restricted to Shipping Documents Tracker Admins</Typography>
        else
            return (
                /* material-table had some issues so I decided to use @material-table/core a fork of material-table
                   i also used the npm install @material-table/core@next to install the latest (pre-release) version compatible with mui version 5
                   issue with non unique id rows warning messages: https://github.com/material-table-core/core/issues/487*/
                <GlobalVariablesContext.Consumer>
                    {(globalVariablesValues) => globalVariablesValues &&
                        <div style={{ padding: 10 }}>
                            <div className='HorizontalOverFlowContainer'>
                                <Paper className="Form" style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <div style={{ width: '100%', overflowY: 'auto', height: 'calc(100vh - 150px)' }}>
                                        <Grid id="UserProfilesViewContainer" container style={{ padding: 5 }} >
                                            <Grid item xs={12}>
                                                {this.state.vendors && this.state.vendors.length > 0 &&
                                                    <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '100%' }} elevation={0} /> }}
                                                        columns={[
                                                            { title: 'Email', field: 'email', align: 'left', validate: rowData => rowData.email ? true : 'Email is required' },
                                                            { title: 'Name', field: 'name', align: 'left', validate: rowData => rowData.name ? true : 'Name is required' },
                                                            { title: 'Agent Company', field: 'agentCompany', align: 'left', validate: rowData => rowData.agentCompany ? true : 'Agent Company is required' },
                                                            { title: 'Account Disabled', field: 'accountDisabled', editable: 'never', align: 'left', type: 'boolean', initialEditValue: false }
                                                        ]}
                                                        data={this.state.vendors}
                                                        editable={{
                                                            onRowAdd: (newData) => new Promise((resolve, reject) => { this.addRecord(newData, resolve, reject) }),
                                                            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => { this.updateRecord(newData, oldData, resolve, reject) }),
                                                            onRowDelete: (oldData) => new Promise((resolve, reject) => { this.deleteRecord(oldData, resolve, reject) }),
                                                        }}
                                                        actions={[
                                                            (rowData) => { return { icon: () => <LockPerson />, hidden: rowData.accountDisabled, tooltip: "Disable Account", onClick: this.disableAccount } },
                                                            (rowData) => { return { icon: () => <ManageAccounts color="error" />, hidden: !rowData.accountDisabled, tooltip: "Enable Account", onClick: this.enableAccount } },
                                                        ]}

                                                        localization={{ header: { actions: '' } }}

                                                        options={{
                                                            idSynonym: 'id', //https://github.com/material-table-core/core/issues/487 to prevent warning on console
                                                            pageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false,
                                                            rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" },
                                                            editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'", color: '#0288D1' },
                                                            actionsCellStyle: { color: '#0288D1' }, headerStyle: { color: '#0288D1' },
                                                        }}
                                                    />}
                                            </Grid>
                                        </Grid>


                                    </div>
                                    <br />

                                </Paper>
                            </div>
                        </div>
                    }
                </GlobalVariablesContext.Consumer>
            )
    }
}
