import React from 'react';
import { NumericFormat } from 'react-number-format';

/**
 * @typedef {object} Props
 * @property {string} name
 * @property {Function} onChange
 */

/** @type React.ComponentType */
export const MyNumberFormat = React.forwardRef(

    /** @type {function(Props, any): import('react').ReactElement} Closure syntax */
    function MyNumberFormat(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat {...other} getInputRef={ref} thousandSeparator allowNegative={false} prefix="NGN   " decimalScale={2} allowLeadingZeros
                onValueChange={(values) => { onChange({ target: { name: props.name, value: values.value, }, }); }}
            />
        );
    }
);
