// @ts-check
import axios from "axios";
import { createStore } from 'state-pool';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const APP_VERSION = process.env.REACT_APP_VERSION
export const SUPPORT_CONTACT = process.env.REACT_APP_SUPPORT_CONTACT
export const SUPPORT_CONTACT_EMAIL = process.env.REACT_APP_SUPPORT_CONTACT_EMAIL
export const APP_LAST_UPDATED = new Date(Number(process.env.REACT_APP_LAST_UPDATED) * 1000)
export const USERGUIDE = process.env.REACT_APP_USERGUIDE
export const ADMINGUIDE = process.env.REACT_APP_ADMINGUIDE

/** @type import('state-pool').Store */ export const globalStore = createStore();  // Create store for storing our global state

/**  @readonly @enum {number} */
export const LoggedOnUserTypes = {
    Vendor: 1,
    TepngUser: 2,
}

/**  @readonly @enum {number} */
export const ServerApiReturnCodes = {
    Ok: 1,
    Unauthorized: 2,
    BadRequest: 3,
    NotFound: 4,
    InternalError: 100
}

/**  @readonly @enum {string} */
export const ShippingRequestStatuses = {
    DRAFT: "Draft",
    REQUEST_SUBMITTED: "Request Submitted",
    DOCUMENTS_SUBMITTED: "Documents Submitted",
    DOCUMENTS_VALIDATED: "Documents Validated",
    DOCUMENTS_REJECTED: "Documents Rejected"
}

/** @param {string} status */
export function getShippingRequestStatusColor(status) {
    switch (status) {
        case ShippingRequestStatuses.DRAFT: return 'DarkGray';
        case ShippingRequestStatuses.REQUEST_SUBMITTED: return 'DarkGoldenRod';
        case ShippingRequestStatuses.DOCUMENTS_SUBMITTED: return 'DeepSkyBlue';
        case ShippingRequestStatuses.DOCUMENTS_VALIDATED: return 'DarkGreen';
        case ShippingRequestStatuses.DOCUMENTS_REJECTED: return 'DarkRed';
        default: return 'DarkGray';
    }
}

/**  @readonly @enum {number} */
export const FormEditMode = {
    CREATE: 1,
    EDIT: 2,
    VIEW: 3
}

export function initializeGlobalStates() {
    axios.defaults.withCredentials = true
    if (!globalStore.value.has("accessToken")) globalStore.setState("accessToken", null)
    if (!globalStore.value.has("loggedOnUserType")) globalStore.setState("loggedOnUserType", null)
    if (!globalStore.value.has("loggedOnTepngUser")) globalStore.setState("loggedOnTepngUser", null)
    if (!globalStore.value.has("vendors")) globalStore.setState("vendors", null)
    if (!globalStore.value.has("loggedOnVendor")) globalStore.setState("loggedOnVendor", null)
}


/**
 * @param {import("axios").Method} method
 * @param {string} url
 * @param {any} data *
 * @param {boolean} serverAuthenticationRequired
 * @param {import("axios").ResponseType} responseType
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<any>>>}
 */
export async function callServerAPI(method, url, data = null, serverAuthenticationRequired, responseType = 'json') {
    return await new Promise(async (resolve, reject) => {
        /** @type AccessToken */ let accessToken = globalStore.getState("accessToken").value
        /** @type import("axios").AxiosRequestConfig */ let config = {}

        // check if server authentication is required and get refresh Accesstoken if it is null or expired
        if (serverAuthenticationRequired && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
            if (await callServerRefreshToken(true)) accessToken = globalStore.getState("accessToken").value
        }

        config.headers = { ...config.headers, Authorization: 'Bearer ' + accessToken?.token }
        config.method = method; config.url = SERVER_URL + url; config.withCredentials = true; config.data = data
        if (responseType) config.responseType = responseType
        await axios.request(config)
            .then(function (/** @type import("axios").AxiosResponse<ServerAPIResponse<any>> */ response) {
                resolve(response)
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                console.log(error);
                if (error?.response?.status) // if a response was returned from server
                    reject(error.response)
                else
                    reject({ data: { message: error.message } }) // create a pseudo response object with axios error message as message. would serve for Network errors etc
            });
    });
}

/**
 * @param {VendorLoginDto} vendorLoginDto
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<any>>>}
 */
export async function callServerVendorPreLogin2FA(vendorLoginDto) {
    return await new Promise(async (resolve, reject) => {
        /** @type import("axios").AxiosRequestConfig */ var config = {}
        config.method = 'POST'; config.data = vendorLoginDto; config.url = SERVER_URL + '/api/AuthenticateVendor/PreLogin2FA'; config.withCredentials = true
        await axios.request(config)
            .then(function (/** @type import("axios").AxiosResponse<ServerAPIResponse<any>> */ response) {
                resolve(response)
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                console.log(error);
                reject(error.response)
            });
    });
}

/**
 * @param {VendorLoginDto} vendorLoginDto
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<VendorLoginReturnData>>>}
 */
export async function callServerVendorLogin(vendorLoginDto) {
    return await new Promise(async (resolve, reject) => {
        /** @type import("axios").AxiosRequestConfig */ var config = {}
        config.method = 'POST'; config.data = vendorLoginDto; config.url = SERVER_URL + '/api/AuthenticateVendor/Login'; config.withCredentials = true
        await axios.request(config)
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<VendorLoginReturnData>> */ response) {
                const accessToken = response.data.returnData.accessToken
                globalStore.getState("accessToken").updateValue(state => accessToken)
                globalStore.getState("loggedOnVendor").updateValue(state => response.data.returnData.vendor)
                globalStore.getState("vendors").updateValue(state => [response.data.returnData.vendor])
                globalStore.getState("loggedOnUserType").updateValue(state => LoggedOnUserTypes.Vendor)
                resolve(response)
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                console.log(error);
                reject(error.response)
            });
    });
}

/**
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<string>>>}
 */
export async function callServerVendorLogout() {
    return await new Promise(async (resolve, reject) => {
        await callServerAPI('POST', '/api/AuthenticateVendor/Logout', null, true)
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<string>> */ response) {
                globalStore.getState("accessToken").updateValue(state => null)
                globalStore.getState("loggedOnVendor").updateValue(state => null)
                resolve(response) // would not get called due to redirect
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                console.log(error);
                reject(error.response)
            });
    });
}

/**
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<TepngUserLoginReturnData>>>}
 */
export async function callServerTepngUserLogin() {
    return await new Promise(async (resolve, reject) => {
        /** @type import("axios").AxiosRequestConfig */ var config = {}
        config.method = 'POST'; config.data = null; config.url = SERVER_URL + '/api/AuthenticateTepngUser/Login'; config.withCredentials = true
        await axios.request(config)
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<TepngUserLoginReturnData>> */ response) {
                const accessToken = response.data.returnData.accessToken
                globalStore.getState("accessToken").updateValue(state => accessToken)
                globalStore.getState("loggedOnTepngUser").updateValue(state => response.data.returnData.tepngUser)
                globalStore.getState("loggedOnUserType").updateValue(state => LoggedOnUserTypes.TepngUser)
                await loadVendors(accessToken) // only Tepng Users can load vendors
                resolve(response)
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                console.log(error);
                reject(error.response)
            });
    });
}

/**
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<string>>>}
 */
export async function callServerTepngUserLogout() {
    return await new Promise(async (resolve, reject) => {
        await callServerAPI('POST', '/api/AuthenticateTepngUser/Logout', null, true)
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<string>> */ response) {
                globalStore.getState("accessToken").updateValue(state => null)
                globalStore.getState("loggedOnTepngUser").updateValue(state => null)
                let url = new URL(window.location.origin)
                // needed to logout of microsoft - the frond end url needs to be added to Azure App registration Redirect URIs to allow for logout redirect
                window.location.replace(SERVER_URL + '/.auth/logout?post_logout_redirect_uri=' + window.encodeURIComponent(url.href));
                resolve(response) // would not get called due to redirect
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                console.log(error);
                reject(error.response)
            });
    });
}

/**
 * @param {boolean} redirectToLoginIfFailedRefreshToken
 * @return {Promise<boolean>}
 */
export async function callServerRefreshToken(redirectToLoginIfFailedRefreshToken) {
    let success = false
    /** @type LoggedOnUserTypes */ const loggedOnUserType = globalStore.getState("loggedOnUserType").value
    if (loggedOnUserType === LoggedOnUserTypes.Vendor || loggedOnUserType == null) {
        console.log(getCurrentTimeString() + ": callServerRefreshToken - Vendor - loggedOnUserType = " + loggedOnUserType)
        await axios.post(SERVER_URL + '/api/AuthenticateVendor/RefreshToken', { withCredentials: true })
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<VendorLoginReturnData>> */ response) {
                const accessToken = response.data.returnData.accessToken
                globalStore.getState("accessToken").updateValue(state => accessToken)
                globalStore.getState("loggedOnVendor").updateValue(state => response.data.returnData.vendor)
                globalStore.getState("vendors").updateValue(state => [response.data.returnData.vendor])
                globalStore.getState("loggedOnUserType").updateValue(state => LoggedOnUserTypes.Vendor)
                success = true
            })
            .catch(function (error) {
                console.log(getCurrentTimeString() + ': ' + error);
                globalStore.getState("accessToken").updateValue(state => null)
                globalStore.getState("loggedOnVendor").updateValue(state => null)
            });
    }

    if ((loggedOnUserType === LoggedOnUserTypes.TepngUser || loggedOnUserType == null) && success === false) {
        console.log(getCurrentTimeString() + ": callServerRefreshToken - TepngUser - loggedOnUserType = " + loggedOnUserType)
        await axios.post(SERVER_URL + '/api/AuthenticateTepngUser/RefreshToken', { withCredentials: true })
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<TepngUserLoginReturnData>> */ response) {
                const accessToken = response.data.returnData.accessToken
                globalStore.getState("accessToken").updateValue(state => accessToken)
                globalStore.getState("loggedOnTepngUser").updateValue(state => response.data.returnData.tepngUser)
                globalStore.getState("loggedOnUserType").updateValue(state => LoggedOnUserTypes.TepngUser)
                await loadVendors(accessToken) // only Tepng Users can load vendors
                success = true
            })
            .catch(function (error) {
                console.log(getCurrentTimeString() + ': ' + error);
                globalStore.getState("accessToken").updateValue(state => null)
                globalStore.getState("loggedOnTepngUser").updateValue(state => null)
            });
    }

    if (success === false && redirectToLoginIfFailedRefreshToken) {
        window.alert("Your session has expired, you will be redirected to login again")
        window.location.href = "/login"
        return success
    }
    else
        return success //return await new Promise(async (resolve, reject) => { resolve(success) });

}

/**
 * @param {AccessToken} accessToken
 * @return {Promise<boolean>}
 */
async function loadVendors(accessToken) {
    var success = false
    /** @type import("axios").AxiosRequestConfig */ var config = {}
    config.headers = { ...config.headers, Authorization: 'Bearer ' + accessToken.token }
    config.method = 'GET'; config.data = null; config.url = SERVER_URL + '/api/Vendor'; config.withCredentials = true
    await axios.request(config)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Vendor[]>> } */ response) {
            const vendors = response.data.returnData
            globalStore.getState("vendors").updateValue(state => vendors)
        })
        .catch(function (/** @type import("axios").AxiosError */ error) {
            console.log(error);
            alert("Error getting Agents List: " + error.message)
        });
    return success
}

export const StatusMessageTypes = {
    Failure: 1,
    Information: 2,
    Warning: 3,
    Success: 4
}

export function replaceNullWithEmptyString(obj) {
    return obj === null ? "" : obj
}

export function replaceNullEmptyWithText(obj, text) {
    return isNullUndefinedOrEmpty(obj) ? text : obj
}

/** @type {function(string): boolean} Closure syntax */
export function isNullUndefinedOrEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
}

/** @type {function(any): any} Closure syntax */
export function replaceUndefinedWithNull(val) {
    return val === undefined ? null : val
}

/** @type {function(string): string} Closure syntax */
export function convertHtmlToText(inputText) {
    var returnText = "" + inputText;

    //-- remove BR tags and replace them with line break
    returnText = returnText.replace(/<br>/gi, "\n");
    returnText = returnText.replace(/<br\s\/>/gi, "\n");
    returnText = returnText.replace(/<br\/>/gi, "\n");

    //-- remove P and A tags but preserve what's inside of them
    returnText = returnText.replace(/<p.*>/gi, "\n");
    returnText = returnText.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 ($1)");

    //-- remove all inside SCRIPT and STYLE tags
    returnText = returnText.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, "");
    returnText = returnText.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, "");
    //-- remove all else
    returnText = returnText.replace(/<(?:.|\s)*?>/g, "");

    //-- get rid of more than 2 multiple line breaks:
    returnText = returnText.replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, "\n\n");

    //-- get rid of more than 2 spaces:
    returnText = returnText.replace(/ +(?= )/g, '');

    //-- get rid of html-encoded characters:
    returnText = returnText.replace(/&nbsp;/gi, " ");
    returnText = returnText.replace(/&amp;/gi, "&");
    returnText = returnText.replace(/&quot;/gi, '"');
    returnText = returnText.replace(/&lt;/gi, '<');
    returnText = returnText.replace(/&gt;/gi, '>');

    return returnText;
}

/** @type {function(string, number): string} Closure syntax */
export function truncateTextToWhiteSpace(text, numChars) {
    if (text.length <= numChars)
        return text
    var truncatedText = text.substring(0, numChars)
    return truncatedText.substring(0, truncatedText.lastIndexOf(' ')) + " ..."
}

export function formatLongDateString(dtStr, includeTime = false) {
    /** @type Intl.DateTimeFormatOptions */ var dateOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    /** @type Intl.DateTimeFormatOptions */ var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}

export function formatShortDateString(dtStr, includeTime = false) {
    /** @type Intl.DateTimeFormatOptions */ var dateOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    /** @type Intl.DateTimeFormatOptions */ var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}

export function formatTimeString(dtStr) {
    /** @type Intl.DateTimeFormatOptions */ var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    var dt = new Date(dtStr)
    return dt.toLocaleTimeString('en-GB', timeOptions)
}

export function getCurrentTimeString() {
    const date = new Date()
    return ('0' + (date.getUTCHours())).slice(-2) + ':' + ('0' + (date.getUTCMinutes())).slice(-2) + ':' + ('0' + (date.getUTCSeconds())).slice(-2) + ':' + date.getUTCMilliseconds()
}

/** @type {function(string): string} Closure syntax */
export function getNameFromEmail(email) {
    const name = email.slice(0, email.indexOf('@'))
    return name.replace('.', ' ')
}

/** @type {function(string): string} Closure syntax */
export function getUndoTooltipForRequest(status) {
    switch (status) {
        case ShippingRequestStatuses.REQUEST_SUBMITTED:
            return "Undo Request Submission";
        case ShippingRequestStatuses.DOCUMENTS_SUBMITTED:
            return "Undo Documents Submission";
        case ShippingRequestStatuses.DOCUMENTS_VALIDATED:
            return "Undo Documents Validation";
        default:
            return '';
    }
}

/** @type {function(ShippingRequest, LoggedOnUserTypes): boolean} Closure syntax */
export function CheckShippingRequestEditable(request, loggedOnUserType) {
    if (loggedOnUserType === LoggedOnUserTypes.TepngUser && (request.status === ShippingRequestStatuses.DRAFT || request.status === ShippingRequestStatuses.DOCUMENTS_SUBMITTED))
        return true;
    if (loggedOnUserType === LoggedOnUserTypes.Vendor && (request.status === ShippingRequestStatuses.REQUEST_SUBMITTED || request.status === ShippingRequestStatuses.DOCUMENTS_REJECTED))
        return true;
    return false;
}

/** @type {function(ShippingRequest, LoggedOnUserTypes): boolean} Closure syntax */
export function CheckShippingRequestUndoable(request, loggedOnUserType) {
    if (loggedOnUserType === LoggedOnUserTypes.TepngUser && (request.status === ShippingRequestStatuses.REQUEST_SUBMITTED || request.status === ShippingRequestStatuses.DOCUMENTS_VALIDATED))
        return true;
    if (loggedOnUserType === LoggedOnUserTypes.Vendor && request.status === ShippingRequestStatuses.DOCUMENTS_SUBMITTED)
        return true;
    return false;
}