// @ts-check
import React, { Component } from "react";
import { Typography, Toolbar, AppBar } from "@mui/material";
import './App.css'
import { ENVIRONMENT, APP_VERSION, APP_LAST_UPDATED, SUPPORT_CONTACT, SUPPORT_CONTACT_EMAIL } from "./utils";
import MediaQuery from "react-responsive";

/**
 * @typedef {object} Props
 * @property {Number} appBarHeight
 * @property {ServerGlobalSettings} serverGlobalSettings
 * @extends {Component<Props>}
 */
export class AppBarFooter extends Component {

    /** @type { {appBar:React.CSSProperties} } */
    styles = {
        appBar: {
            height: this.props.appBarHeight,
            padding: 5,
            top: 'auto',
            bottom: 0,
            backgroundColor: "#FFFFFF",
            zIndex: 1300
        }
    }

    render() {
        return (
            <AppBar style={this.styles.appBar} >
                <Toolbar classes={{ regular: "ToolbarRegular" }}>
                    <img style={{ height: '40px' }} src='/total_logo.png' alt="total_logo.png" /> &nbsp;&nbsp;
                    <MediaQuery minWidth={1224}>
                        <Typography variant="subtitle1" color="error" >TotalEnergies EP Nigeria</Typography>  <div style={{ flexGrow: 1 }} />
                        <Typography variant="caption" color="textPrimary">Product Development Contact:</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="caption" color="textPrimary"><a href={'mailto:' + SUPPORT_CONTACT_EMAIL} style={{ color: 'red', textDecoration: 'none' }}>{SUPPORT_CONTACT} </a> </Typography>
                    </MediaQuery>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant="caption" color="textSecondary"
                        title={
                            "Client Environment: " + ENVIRONMENT +
                            "\nServer Environment: " + this.props.serverGlobalSettings.environment +
                            "\nClient App Version: " + APP_VERSION +
                            "\nServer App Version: " + this.props.serverGlobalSettings.appVersion +
                            "\nClient Last Updated: " + APP_LAST_UPDATED +
                            "\nServer Last Updated: " + new Date(this.props.serverGlobalSettings.appLastUpdated)}>
                        &copy; 2022. All rights reserved.  &nbsp;<span style={{ color: "red" }}>{'(' + ENVIRONMENT + ')'}</span>
                    </Typography>

                </Toolbar>
            </AppBar>
        )
    }
}
