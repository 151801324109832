// @ts-check
import React, { Component } from "react";
import { callServerTepngUserLogout, callServerVendorLogout, LoggedOnUserTypes, USERGUIDE, ADMINGUIDE } from "./utils";
import { Typography, Toolbar, AppBar, IconButton, Menu, MenuItem, Divider, ListItemIcon, } from "@mui/material";
import { AccountCircle, Logout, Menu as MenuIcon, Home, Quiz, Password } from "@mui/icons-material";
import './App.css'
import withRouter from "./WithRouter";
import MediaQuery from "react-responsive";

/**
 * @typedef {object} Props
 * @property {import('react-router-dom').Params<string>} routerParams
 * @property {import('react-router-dom').Location} routerLocation
 * @property {import('react-router-dom').NavigateFunction} routerNavigate
 * @property {TepngUser} loggedOnTepngUser
 * @property {Vendor} loggedOnVendor
 * @property {LoggedOnUserTypes} loggedOnUserType
 * @property {Number} appBarHeight
 * @property {string} header
 * @property {Function} onToggleDrawer
 * @extends {Component<Props>}
 */

class AppBarHeader extends Component {

    /** @type { {appBar:React.CSSProperties} } */
    styles = {
        appBar: {
            height: this.props.appBarHeight,
            zIndex: 1300,
            top: 0,
            backgroundColor: "#FFFFFF",
            border: 1,
            borderColor: "#000000"
        }
    }

    constructor(props) {
        super(props);
        //declare variables to hold all function binding to class instance
        this.logout = this.logout.bind(this)
        this.displayUserProfile = this.displayUserProfile.bind(this)
        //set initial state
        /** @type { { showProfileMenu:Boolean, profileMenuAnchorEl:Element|null, showUserGuideMenu:Boolean, userGuideMenuAnchorEl:Element|null}} */
        this.state = {
            showProfileMenu: false,
            profileMenuAnchorEl: null,
            showUserGuideMenu: false,
            userGuideMenuAnchorEl: null
        }
    }

    async logout(event) {
        this.setState({ showProfileMenu: false })
        if (this.props.loggedOnUserType === LoggedOnUserTypes.TepngUser)
            await callServerTepngUserLogout()
        else
            await callServerVendorLogout()
    }

    displayUserProfile(event) {
        this.setState({ showProfileMenu: false })
    }

    render() {
        return (
            <AppBar style={this.styles.appBar}>
                <Toolbar classes={{ regular: "ToolbarRegular" }}>
                    <IconButton color="error" onClick={this.props.onToggleDrawer.bind(null)} edge="start" > <MenuIcon /></IconButton>
                    <MediaQuery minWidth={1224}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton color="error" onClick={() => this.props.routerNavigate("/")}><Home /></IconButton>&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <img style={{ height: '30px' }} src='/favicon.png' alt="favicon.png" /> &nbsp;&nbsp;&nbsp;
                        <Typography variant="h6" color="error" fontWeight="bold" > Shipping Documents Tracker {this.props.header ? ' - ' + this.props.header : ""}  </Typography>
                    </MediaQuery>
                    <MediaQuery maxWidth={1223}>
                        &nbsp;&nbsp;
                        <Typography variant="subtitle1" color="error" fontWeight="bold" > Shipping Documents Tracker    </Typography>
                    </MediaQuery>
                    <div style={{ flexGrow: 1 }} />
                    {/* <Badge badgeContent={0} color="secondary" max={9}  >
                        <NotificationsOutlined color="secondary" />
                    </Badge>&nbsp;&nbsp;&nbsp;&nbsp; */}
                    {(this.props.loggedOnTepngUser || this.props.loggedOnVendor) && this.props.loggedOnUserType &&
                        <>
                            <MediaQuery minWidth={1224}>
                                <Typography variant="subtitle2" color="textPrimary">Welcome {this.props.loggedOnUserType === LoggedOnUserTypes.TepngUser ? this.props.loggedOnTepngUser.firstName + ' ' + this.props.loggedOnTepngUser.lastName : this.props.loggedOnVendor.name}</Typography>
                            </MediaQuery>
                            <IconButton color="secondary" edge="end" title={this.props.loggedOnUserType === LoggedOnUserTypes.TepngUser ? this.props.loggedOnTepngUser.email : this.props.loggedOnVendor.email} onClick={(event) => { this.setState({ profileMenuAnchorEl: event.currentTarget, showProfileMenu: !this.state.showProfileMenu }) }}> <AccountCircle fontSize="large" /></IconButton>&nbsp;
                            <Menu style={{ zIndex: 1400 }} anchorEl={this.state.profileMenuAnchorEl} open={this.state.showProfileMenu} onClose={() => { this.setState({ showProfileMenu: false }) }}>
                                <MenuItem onClick={this.displayUserProfile}><ListItemIcon><AccountCircle /></ListItemIcon>Profile</MenuItem>
                                <Divider />
                                {this.props.loggedOnUserType === LoggedOnUserTypes.Vendor &&
                                    <>
                                        <MenuItem onClick={() => this.props.routerNavigate("/change-password")}><ListItemIcon> <Password /> </ListItemIcon>Change Password</MenuItem>
                                        <Divider />
                                    </>
                                }
                                <MenuItem onClick={this.logout}><ListItemIcon><Logout /></ListItemIcon>Logout</MenuItem>
                            </Menu>
                            &nbsp;
                        </>
                    }
                    <MediaQuery minWidth={1224}><Typography variant="subtitle2" color="textPrimary">User Guides</Typography></MediaQuery>
                    <IconButton size="small" color="secondary" edge="start" title="User Guides" onClick={(event) => { this.setState({ userGuideMenuAnchorEl: event.currentTarget, showUserGuideMenu: !this.state.showUserGuideMenu }) }}> <Quiz fontSize="large" /></IconButton>&nbsp;
                    <Menu anchorEl={this.state.userGuideMenuAnchorEl} open={this.state.showUserGuideMenu} onClose={() => { this.setState({ showUserGuideMenu: false }) }}>
                        <MenuItem onClick={(event) => { window.open('/' + USERGUIDE + '?chromeless=true', '_blank')?.focus(); }}><ListItemIcon><Quiz /></ListItemIcon>User Guide / Manual</MenuItem>
                        <Divider />
                        <MenuItem onClick={(event) => { window.open('/' + ADMINGUIDE + '?chromeless=true', '_blank')?.focus(); }}><ListItemIcon><Quiz /></ListItemIcon>Administrator Guide</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar >
        )
    }
}

export default withRouter(AppBarHeader);
