import './App.css';
import React, { Component } from "react";
import { Box, Button, Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { GlobalVariablesContext } from './Context';
import { callServerAPI, formatShortDateString, FormEditMode, getNameFromEmail, getShippingRequestStatusColor, globalStore, LoggedOnUserTypes, ShippingRequestStatuses } from './utils';
import withRouter from './WithRouter';
import { AttachFile, DeleteOutline, Download, FileCopy, PostAdd, Save, Send, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';
import { MyNumberFormat } from './MyNumberFormat';



/**
 * @typedef {object} Props
 * @property {import('react-router-dom').Params<string>} routerParams
 * @property {import('react-router-dom').Location} routerLocation
 * @property {import('react-router-dom').NavigateFunction} routerNavigate
 * @property {FormEditMode} formEditMode
 * @property {TepngUser} loggedOnTepngUser
 * @property {Vendor} loggedOnVendor
 * @property {LoggedOnUserTypes} loggedOnUserType
 * @property {ShippingDocumentType[]} shippingDocumentTypes
 * @property {ShipmentClass[]} shipmentClasses
 * @extends {Component<Props>}
 */

class CreateEditShippingRequest extends Component {
  /** @type { React.Context<GlobalVariables>} */
  static contextType = GlobalVariablesContext;
  /** @type React.ContextType < typeof GlobalVariablesContext >*/
  context = this.context

  /** @type { { "& .MuiInput-underline:after": React.CSSProperties, '& .MuiInput-underline:hover:before':React.CSSProperties, '& .MuiInput-underline:hover:after':React.CSSProperties} } */
  textFieldStyle = {
    "& .MuiInput-underline:after": {
      borderBottomColor: 'red'
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: 'red',
    },
    "& .MuiInput-underline:hover:after": {
      borderBottomColor: 'red'
    }
  }

  textFieldDisabledStyle = {
    "& .MuiInput-underline:after": {
      display: 'none'
    },
    "& .MuiInput-underline:before": {
      display: 'none'
    }
  }


  /** @type {ShippingRequest} */
  emptyShippingRequest = {
    shipmentClassId: 0, id: 0, title: '', status: 'Draft', agentCompany: '#NoAgent', blawbNumber: '', poNumber: '', paarNumber: '', customCNos: '',
    cif: 0, duty: 0, createdBy: '', createdDate: new Date(), updatedBy: '', updatedDate: null, requestedBy: '', requestedDate: null, documentsSubmittedBy: '',
    documentsSubmittedDate: null, validatedBy: '', validatedDate: null, rejectedBy: '', rejectedDate: null, validationComments: ''
  }

  constructor(props) {
    super(props);
    //declare variables to hold all function binding to class instance
    this.updateFormValue = this.updateFormValue.bind(this)
    this.updateFormNumericValue = this.updateFormNumericValue.bind(this)
    this.updateCheckBoxValue = this.updateCheckBoxValue.bind(this)
    this.loadShippingRequest = this.loadShippingRequest.bind(this)
    this.loadShippingRequestDocuments = this.loadShippingRequestDocuments.bind(this)
    this.refreshPendingDocumentsList = this.refreshPendingDocumentsList.bind(this)
    this.CreateRequest = this.CreateRequest.bind(this)
    this.UpdateRequest = this.UpdateRequest.bind(this)
    this.CreateAndSubmitRequest = this.CreateAndSubmitRequest.bind(this)
    this.UpdateAndSubmitRequest = this.UpdateAndSubmitRequest.bind(this)
    this.SubmitDocuments = this.SubmitDocuments.bind(this)
    this.UploadDocument = this.UploadDocument.bind(this)
    this.ValidateDocuments = this.ValidateDocuments.bind(this)
    this.RejectDocuments = this.RejectDocuments.bind(this)

    /** @type {Vendor[]} */const vendors = globalStore.getState("vendors").value

    //set initial state
    /** @type { {shippingRequest: ShippingRequest, shippingDocuments: ShippingDocument[], notUploadedShippingDocumentTypes:ShippingDocumentType[], pendingShippingDocumentTypeIds:number[], vendors:Vendor[], currentDocumentTypeId:number, currentDocumentReferenceId:string,requestEditEnabled:boolean,documentsEditEnabled:boolean,documentsValidationEnabled:boolean} } */
    this.state = {
      shippingRequest: this.emptyShippingRequest,
      shippingDocuments: [],
      notUploadedShippingDocumentTypes: [],
      pendingShippingDocumentTypeIds: [],
      vendors: vendors,
      currentDocumentTypeId: 0,
      currentDocumentReferenceId: '',
      requestEditEnabled: false,
      documentsEditEnabled: false,
      documentsValidationEnabled: false
    }
  }

  componentDidMount() {
    this.context.setHeader(this.props.formEditMode === FormEditMode.CREATE ? "Create New Request" : (this.props.formEditMode === FormEditMode.VIEW ? "View Shipping Request" : "Edit Shipping Request"))
    // Subscribe to global store changes
    let self = this
    globalStore.getState("vendors").subscribe(function (value) {
      self.setState({ vendors: value })
    })
    //load shipping request
    if (this.props.formEditMode === FormEditMode.EDIT || this.props.formEditMode === FormEditMode.VIEW) {
      let { id } = this.props.routerParams
      this.loadShippingRequest(id)
    }
    else {
      this.setState({ shippingRequest: this.emptyShippingRequest, shippingDocuments: [], requestEditEnabled: true })
    }
  }

  loadShippingRequest(id) {
    var self = this;
    self.context.showBackdrop('Loading Request ...')
    callServerAPI('GET', `/api/Request/${id}`, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingRequest>> } */ response) {
        /** @type { ShippingRequest } */const request = response.data.returnData
        self.setState({
          shippingRequest: request,
          requestEditEnabled: self.props.formEditMode !== FormEditMode.VIEW && request.status === ShippingRequestStatuses.DRAFT && self.props.loggedOnUserType === LoggedOnUserTypes.TepngUser && self.props.loggedOnTepngUser?.isTransitOfficer,
          documentsEditEnabled: self.props.formEditMode !== FormEditMode.VIEW && (request.status === ShippingRequestStatuses.REQUEST_SUBMITTED || request.status === ShippingRequestStatuses.DOCUMENTS_REJECTED) && self.props.loggedOnUserType === LoggedOnUserTypes.Vendor,
          documentsValidationEnabled: self.props.formEditMode !== FormEditMode.VIEW && request.status === ShippingRequestStatuses.DOCUMENTS_SUBMITTED && self.props.loggedOnUserType === LoggedOnUserTypes.TepngUser && self.props.loggedOnTepngUser?.isTransitOfficer,
        }, self.loadShippingRequestDocuments);
        self.context.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  loadShippingRequestDocuments() {
    var self = this;
    self.context.showBackdrop('Loading Documents...')
    callServerAPI('GET', `/api/Request/GetAllDocuments/${this.state.shippingRequest.id}`, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocument[]>> } */ response) {
        self.setState({ shippingDocuments: response.data.returnData }, self.refreshPendingDocumentsList);
        self.context.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  refreshPendingDocumentsList() {
    let shipmentClass = this.props.shipmentClasses.find(e => e.id === this.state.shippingRequest.shipmentClassId)
    if (shipmentClass == null) return
    this.setState({
      notUploadedShippingDocumentTypes: this.props.shippingDocumentTypes.filter(docType => this.state.shippingDocuments.find(e => e.documentTypeId === docType.id) == null)
    });
    this.setState({
      pendingShippingDocumentTypeIds: shipmentClass.requiredDocumentTypeIdsList.filter(typeId => this.state.shippingDocuments.find(e => e.documentTypeId === typeId) == null)
    });
  }

  CreateRequest(event) {
    var self = this;
    /** @type AddUpdateRequestDto */ let addUpdateRequestDto = { agentCompany: this.state.shippingRequest.agentCompany, blawbNumber: this.state.shippingRequest.blawbNumber, poNumber: this.state.shippingRequest.poNumber, shipmentClassId: this.state.shippingRequest.shipmentClassId, title: this.state.shippingRequest.title }
    self.context.showBackdrop('Creating Request...')
    callServerAPI('POST', '/api/Request', addUpdateRequestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingRequest>> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: "Request Created", severity: "success" })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  CreateAndSubmitRequest(event) {
    var self = this;
      /** @type AddUpdateRequestDto */ let addUpdateRequestDto = { agentCompany: this.state.shippingRequest.agentCompany, blawbNumber: this.state.shippingRequest.blawbNumber, poNumber: this.state.shippingRequest.poNumber, shipmentClassId: this.state.shippingRequest.shipmentClassId, title: this.state.shippingRequest.title }
    self.context.showBackdrop('Creating Request...')
    callServerAPI('POST', '/api/Request', addUpdateRequestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingRequest>> } */ response) {
        self.context.showSnackbar({ message: "Request Created", severity: "success" })
        self.setState({ shippingRequest: response.data.returnData }, self.SubmitRequest);
        self.context.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  UpdateRequest(event) {
    var self = this;
    /** @type AddUpdateRequestDto */ let addUpdateRequestDto = { agentCompany: this.state.shippingRequest.agentCompany, blawbNumber: this.state.shippingRequest.blawbNumber, poNumber: this.state.shippingRequest.poNumber, shipmentClassId: this.state.shippingRequest.shipmentClassId, title: this.state.shippingRequest.title }
    self.context.showBackdrop('Updating Request...')
    callServerAPI('PUT', `/api/Request/${this.state.shippingRequest.id}`, addUpdateRequestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingRequest>> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: "Request Updated", severity: "success" })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  UpdateAndSubmitRequest(event) {
    var self = this;
    /** @type AddUpdateRequestDto */ let addUpdateRequestDto = { agentCompany: this.state.shippingRequest.agentCompany, blawbNumber: this.state.shippingRequest.blawbNumber, poNumber: this.state.shippingRequest.poNumber, shipmentClassId: this.state.shippingRequest.shipmentClassId, title: this.state.shippingRequest.title }
    self.context.showBackdrop('Updating Request...')
    callServerAPI('PUT', `/api/Request/${this.state.shippingRequest.id}`, addUpdateRequestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingRequest>> } */ response) {
        self.context.showSnackbar({ message: "Request Updated", severity: "success" })
        self.setState({ shippingRequest: response.data.returnData }, self.SubmitRequest);
        self.context.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  SubmitRequest() {
    var self = this;
    /** @type SubmitRequestDto */ let submitRequestDto = { notifyAgentLink: window.location.origin + '/edit-shippingrequest/' + this.state.shippingRequest.id }
    self.context.showBackdrop('Submiting Request...')
    callServerAPI('PATCH', `/api/Request/SubmitRequest/${this.state.shippingRequest.id}`, submitRequestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: "Request Submitted", severity: "success" })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  SubmitDocuments(event) {
    var self = this;
    /** @type SubmitDocumentsDto */ let submitDocumentsDto = { notifyTransitOfficerLink: window.location.href, paarNumber: this.state.shippingRequest.paarNumber, customCNos: this.state.shippingRequest.customCNos, cif: this.state.shippingRequest.cif, duty: this.state.shippingRequest.duty }
    self.context.showBackdrop('Submiting Uploaded Documents...')
    callServerAPI('PATCH', `/api/Request/SubmitDocuments/${this.state.shippingRequest.id}`, submitDocumentsDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: "Documents Submitted", severity: "success" })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  downloadDocumentPdf(requestid, documentTypeId) {
    var self = this;
    self.context.showBackdrop('Downloading Document Pdf Documents...')
    callServerAPI('GET', `/api/Request/DownloadDocument/${requestid}/${documentTypeId}`, null, true, 'blob')
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        self.context.hideBackdrop()
        window.open(URL.createObjectURL(response.data));
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  UploadDocument(event) {
    event.persist(); //important to persist event so we can reset the value of the input file after an upload
    if (event.target.files.length === 0) return;
    var self = this;
    self.context.showBackdrop('Uploading Document ...')
    const formData = new FormData();
    formData.append("documentFile", event.target.files[0]);
    formData.append("documentTypeId", this.state.currentDocumentTypeId + "");
    formData.append("documentReferenceId", this.state.currentDocumentReferenceId);
    callServerAPI('POST', `/api/Request/UploadDocument/${this.state.shippingRequest.id}`, formData, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingDocument>> } */response) {
        self.setState(previousState => {
          return {
            shippingDocuments: [response.data.returnData, ...previousState.shippingDocuments],
            currentDocumentTypeId: 0,
            currentDocumentReferenceId: ''
          }
        }, self.refreshPendingDocumentsList);
        self.context.showSnackbar({ message: "Document Uploaded", severity: "success" })
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
      .finally(function () {
        event.target.value = null //important to persist event so we can reset the value of the input file after an upload
        self.context.hideBackdrop()
      });
  }

  DeleteDocument(requestId, documentTypeId) {
    var self = this;
    self.context.showBackdrop('Deleting Document ...')
    callServerAPI('DELETE', `/api/Request/DeleteDocument/${requestId}/${documentTypeId}`, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        self.setState({ shippingDocuments: self.state.shippingDocuments.filter(inst => inst.requestId !== requestId || inst.documentTypeId !== documentTypeId) }, self.refreshPendingDocumentsList);
        self.context.showSnackbar({ message: "Document Deleted", severity: "success" })
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
      .finally(function () {
        self.context.hideBackdrop()
      });
  }

  RejectDocuments(event) {
    var self = this;
    /** @type ValidateRejectDocumentsDto */ let rejectDocumentsDto = { validationComments: this.state.shippingRequest.validationComments, notifyAgentLink: window.location.href }
    self.context.showBackdrop('Rejecting Uploaded Documents...')
    callServerAPI('PATCH', `/api/Request/RejectDocuments/${this.state.shippingRequest.id}`, rejectDocumentsDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: "Documents Rejected", severity: "info" })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  ValidateDocuments(event) {
    var self = this;
    /** @type ValidateRejectDocumentsDto */ let rejectDocumentsDto = { validationComments: this.state.shippingRequest.validationComments, notifyAgentLink: window.location.href }
    self.context.showBackdrop('Validating Uploaded Documents...')
    callServerAPI('PATCH', `/api/Request/ValidateDocuments/${this.state.shippingRequest.id}`, rejectDocumentsDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: "Documents Validated", severity: "success" })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  updateFormValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    if (eventTargetName === 'title' || eventTargetName === 'blawbNumber' || eventTargetName === 'poNumber' || eventTargetName === 'agentCompany')
      this.setState({ enableSubmitRequest: false })
    this.setState(({ shippingRequest }) => ({ shippingRequest: { ...shippingRequest, [eventTargetName]: eventTargetValue } }))
  }

  updateFormNumericValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    if (eventTargetName === 'shipmentClassId')
      this.setState({ enableSubmitRequest: false })
    this.setState(({ shippingRequest }) => ({ shippingRequest: { ...shippingRequest, [eventTargetName]: Number(eventTargetValue) } }))
  }

  updateCheckBoxValue(event) {
    let eventTargetName = event.target.name; let eventTargetChecked = event.target.checked;
    this.setState(({ shippingRequest }) => ({ shippingRequest: { ...shippingRequest, [eventTargetName]: eventTargetChecked } }))
  }

  /** @type { (componentName: string, date: Date) => void } */
  updateDateValue(componentName, date) {
    this.setState(({ shippingRequest }) => ({ shippingRequest: { ...shippingRequest, [componentName]: date } }))
  }


  render() {

    return (
      <GlobalVariablesContext.Consumer>
        {(globalVariablesValues) => globalVariablesValues && this.state.vendors && this.state.shippingRequest && this.state.shippingDocuments && this.state.vendors &&
          <>
            <div style={{ padding: 5 }}>
              <Paper className="Form" style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ overflowY: 'auto', height: 'calc(100vh - 150px)', padding: 5 }}>

                  <Grid id="ShippingRequestContainer" container spacing={1} className="FormDetails">

                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <Grid id="ActionsContainer" container spacing={1} >
                        <Grid item xs={12} sm={5} textAlign='center' className="RequestStatusGrid" >
                          <Typography textAlign='center' color="white" bgcolor={getShippingRequestStatusColor(this.state.shippingRequest.status)} style={{ fontSize: 14, padding: 7 }} ><b>{this.state.shippingRequest.status}</b> </Typography>
                        </Grid>

                        {this.state.requestEditEnabled &&
                          <>
                            <Grid item xs={12} sm={7} textAlign='right'>
                              {this.props.formEditMode === FormEditMode.EDIT &&
                                <Button disabled={!(this.state.shippingRequest.title && this.state.shippingRequest.blawbNumber && this.state.shippingRequest.poNumber && this.state.shippingRequest.shipmentClassId && this.state.shippingRequest.agentCompany !== '#NoAgent')}
                                  variant="contained" size="large" color="info" onClick={this.UpdateRequest} ><Save /> &nbsp;Update </Button>
                              }
                              {this.props.formEditMode === FormEditMode.CREATE &&
                                <Button disabled={!(this.state.shippingRequest.title && this.state.shippingRequest.blawbNumber && this.state.shippingRequest.poNumber && this.state.shippingRequest.shipmentClassId && this.state.shippingRequest.agentCompany !== '#NoAgent')}
                                  variant="contained" size="large" color="info" onClick={this.CreateRequest} ><Save /> &nbsp;Create </Button>
                              }
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {this.props.formEditMode === FormEditMode.EDIT &&
                                <Button disabled={!(this.state.shippingRequest.title && this.state.shippingRequest.blawbNumber && this.state.shippingRequest.poNumber && this.state.shippingRequest.shipmentClassId && this.state.shippingRequest.agentCompany !== '#NoAgent')}
                                  variant="contained" size="large" color="error" onClick={this.UpdateAndSubmitRequest} ><Send /> &nbsp;Submit </Button>
                              }
                              {this.props.formEditMode === FormEditMode.CREATE &&
                                <Button disabled={!(this.state.shippingRequest.title && this.state.shippingRequest.blawbNumber && this.state.shippingRequest.poNumber && this.state.shippingRequest.shipmentClassId && this.state.shippingRequest.agentCompany !== '#NoAgent')}
                                  variant="contained" size="large" color="error" onClick={this.CreateAndSubmitRequest} ><Send /> &nbsp;Submit </Button>
                              }
                            </Grid>
                          </>
                        }
                        {this.state.documentsEditEnabled &&
                          <Grid item xs={12} sm={7} textAlign='right'>
                            <Button disabled={!(this.state.pendingShippingDocumentTypeIds?.length === 0 && this.state.shippingRequest.paarNumber && this.state.shippingRequest.cif && this.state.shippingRequest.customCNos && this.state.shippingRequest.duty)}
                              variant="contained" size="large" color="error" onClick={this.SubmitDocuments} ><FileCopy /> &nbsp;Submit </Button>
                          </Grid>
                        }
                        {this.state.documentsValidationEnabled &&
                          <>
                            <Grid item xs={12} bgcolor='#0288D1' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc", marginTop: 10 }}>
                              <Typography textAlign='center' color="white" style={{ fontSize: 14 }} ><b>Validation</b> </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <TextField fullWidth required name="validationComments" type="text" variant="standard" sx={this.textFieldStyle} size="medium" placeholder='Enter Validation Comments'
                                value={this.state.shippingRequest.validationComments ?? ''} onChange={this.updateFormValue} />
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 5, paddingBottom: 5 }} textAlign='center'>
                              <Button disabled={!this.state.shippingRequest.validationComments} variant="contained" size="large" color="success" onClick={this.ValidateDocuments} ><ThumbUpAlt /> &nbsp;Validate </Button>&nbsp;
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 5, paddingBottom: 5 }} textAlign='center'>
                              <Button disabled={!this.state.shippingRequest.validationComments} variant="contained" size="large" color="error" onClick={this.RejectDocuments} ><ThumbDownAlt /> &nbsp;Reject </Button>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={9}>
                      <Grid id="CreateEditRequestContainer" container spacing={1} >

                        <Grid item xs={12} borderColor="#cccccc" bgcolor='#0288D1' style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}>
                          <Typography color="white" style={{ fontSize: 14 }} ><b>Shipping Request Details (TotalEnergies)</b> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >Title</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <TextField fullWidth required name="title" type="text" variant="standard" size="small" disabled={!this.state.requestEditEnabled} sx={this.state.requestEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.title ?? ''} onChange={this.updateFormValue} />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >BL / AWB No.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth required name="blawbNumber" type="text" variant="standard" size="small" disabled={!this.state.requestEditEnabled} sx={this.state.requestEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.blawbNumber ?? ''} onChange={this.updateFormValue} />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >Shipment Class</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth select variant="standard" size="small" name="shipmentClassId" disabled={!this.state.requestEditEnabled} sx={this.state.requestEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.shipmentClassId} onChange={this.updateFormNumericValue}>
                            <MenuItem key={0} value={0}>Select Shipment Class</MenuItem >
                            {this.props.shipmentClasses.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >PO No.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth required name="poNumber" type="text" variant="standard" size="small" disabled={!this.state.requestEditEnabled} sx={this.state.requestEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.poNumber ?? ''} onChange={this.updateFormValue} />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >Agent</Typography>
                        </Grid>
                        <Grid item xs={9} lg={4}>
                          <TextField fullWidth select variant="standard" size="small" name="agentCompany" disabled={!this.state.requestEditEnabled} sx={this.state.requestEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.agentCompany} onChange={this.updateFormValue}>
                            <MenuItem key='0' value='#NoAgent'>Select Agent</MenuItem >
                            {[...new Set(this.state.vendors.map(e => e.agentCompany))].sort().map(agent => <MenuItem key={agent} value={agent}>{agent}</MenuItem >)}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} borderColor="#cccccc" bgcolor='#0288D1' style={{ marginTop: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}>
                          <Typography color="white" style={{ fontSize: 14 }} ><b>Shipping Document Details (Agent)</b> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >PAAR No</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth required name="paarNumber" type="text" variant="standard" size="small" disabled={!this.state.documentsEditEnabled} sx={this.state.documentsEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.paarNumber} onChange={this.updateFormValue} />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >Custom C Nos.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth required name="customCNos" type="text" variant="standard" size="small" disabled={!this.state.documentsEditEnabled} sx={this.state.documentsEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.customCNos} onChange={this.updateFormValue} />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >CIF </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth required name="cif" type="text" variant="standard" size="small" disabled={!this.state.documentsEditEnabled} sx={this.state.documentsEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.cif} onChange={this.updateFormNumericValue} InputProps={{ inputComponent: MyNumberFormat }} />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                          <Typography color="secondary" >DUTY </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={4}>
                          <TextField fullWidth required name="duty" type="text" variant="standard" size="small" disabled={!this.state.documentsEditEnabled} sx={this.state.documentsEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                            value={this.state.shippingRequest.duty} onChange={this.updateFormNumericValue} InputProps={{ inputComponent: MyNumberFormat }} />
                        </Grid>
                        <Grid item xs={12} borderColor="#cccccc" bgcolor='#0288D1' style={{ marginTop: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}>
                          <Typography color="white" style={{ fontSize: 14 }} ><b>Shipping Document Uploads (Agent)</b> </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Grid id="DocumentsListContainer" container spacing={0}  >
                            <Grid item xs={12} > <Typography color="secondary" >Uploaded Documents</Typography></Grid>
                            <Grid item xs={12} > <Typography color="primary" >&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No document uploaded</Typography></Grid>
                            {this.state.shippingDocuments.map(doc =>
                              <>
                                <Grid item xs={12}>
                                  <Typography color="primary" >
                                    <IconButton disabled={!this.state.documentsEditEnabled} size="small" style={{ color: '#7777777' }} onClick={this.DeleteDocument.bind(this, doc.requestId, doc.documentTypeId)} title='Delete Document'><DeleteOutline />  </IconButton> &nbsp;
                                    <IconButton size="small" color="secondary" onClick={this.downloadDocumentPdf.bind(this, doc.requestId, doc.documentTypeId)} title='Download Document'><Download />  </IconButton> &nbsp;&nbsp;
                                    {this.props.shippingDocumentTypes.find(e => e.id === doc.documentTypeId)?.name}
                                    {doc.documentReferenceId ? `: (${this.props.shippingDocumentTypes.find(e => e.id === doc.documentTypeId)?.documentReferenceIdType ?? 'Ref ID'} = ${doc.documentReferenceId})` : ''}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} style={{ marginTop: 10 }} > <Typography color="error" >Pending Documents</Typography></Grid>
                            {this.state.pendingShippingDocumentTypeIds.map(docTypeId =>
                              <>
                                <Grid item xs={12}>
                                  <Typography color="primary" >
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconButton disabled={!this.state.documentsEditEnabled} id={docTypeId + ''} size="small" color="secondary" onClick={() => this.setState({ currentDocumentTypeId: docTypeId })} title='Select this Document Type for Upload'><PostAdd /> </IconButton> &nbsp;&nbsp;
                                    {this.props.shippingDocumentTypes.find(e => e.id === docTypeId)?.name}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>

                        {this.state.documentsEditEnabled &&
                          <Grid item xs={12} lg={6} >
                            <Grid id="AddDocumentsContainer" container spacing={2}  >
                              <Grid item xs={12} sm={4} >
                                <Typography color="secondary" >Document Type</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextField fullWidth select variant="standard" size="small" name="currentDocumentTypeId" disabled={!this.state.documentsEditEnabled} sx={this.state.documentsEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                                  value={this.state.currentDocumentTypeId} onChange={(e) => this.setState({ currentDocumentTypeId: Number(e.target.value) })}>
                                  <MenuItem key={0} value={0}>Select Document Type</MenuItem >
                                  {this.state.notUploadedShippingDocumentTypes.map(docType =>
                                    <MenuItem key={docType.id} value={docType.id}>{docType.name}</MenuItem >
                                  )}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={4} >
                                <Typography color="secondary" >{this.props.shippingDocumentTypes.find(e => e.id === this.state.currentDocumentTypeId)?.documentReferenceIdType ?? 'Document Reference ID'}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextField fullWidth required name="currentDocumentReferenceId" type="text" variant="standard" disabled={!this.state.documentsEditEnabled} sx={this.state.documentsEditEnabled ? this.textFieldStyle : this.textFieldDisabledStyle}
                                  value={this.state.currentDocumentReferenceId} onChange={(e) => this.setState({ currentDocumentReferenceId: e.target.value })} />
                              </Grid>

                              <Grid item xs={12} className="FormHeaderOrFooterDetails">
                                <Button variant="contained" disabled={!this.state.documentsEditEnabled || this.state.currentDocumentTypeId == null || this.state.currentDocumentTypeId === 0} size="medium" color="secondary" component="label" title="Upload Document "><AttachFile /> &nbsp;Upload Document
                                  <input accept="application/pdf" hidden type="file" onChange={this.UploadDocument} />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={3} >
                      <Grid id="RequestStatusContainer" container spacing={1}>
                        {this.state.shippingRequest.status !== ShippingRequestStatuses.DRAFT &&
                          <>
                            <Grid item xs={12} bgcolor='#0288D1' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="white" style={{ fontSize: 14 }} ><b>Requested Submitted By</b> </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12, textTransform: 'capitalize' }} >{getNameFromEmail(this.state.shippingRequest.requestedBy)} </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12 }} >{formatShortDateString(this.state.shippingRequest.requestedDate, true)} </Typography>
                            </Grid>
                          </>
                        }
                        {this.state.shippingRequest.status !== ShippingRequestStatuses.DRAFT && this.state.shippingRequest.status !== ShippingRequestStatuses.REQUEST_SUBMITTED &&
                          <>
                            <Grid item xs={12} bgcolor='#0288D1' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc", marginTop: 20 }}>
                              <Typography textAlign='center' color="white" style={{ fontSize: 14 }} ><b>Documents Submitted By</b> </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12 }} >{this.state.shippingRequest.documentsSubmittedBy} </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12 }} >{formatShortDateString(this.state.shippingRequest.documentsSubmittedDate, true)} </Typography>
                            </Grid>
                          </>
                        }
                        {this.state.shippingRequest.status === ShippingRequestStatuses.DOCUMENTS_REJECTED &&
                          <>
                            <Grid item xs={12} bgcolor='#0288D1' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc", marginTop: 20 }}>
                              <Typography textAlign='center' color="white" style={{ fontSize: 14 }} ><b>Rejected By</b> </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12, textTransform: 'capitalize' }} >{getNameFromEmail(this.state.shippingRequest.rejectedBy)} </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12 }} >{formatShortDateString(this.state.shippingRequest.rejectedDate, true)} </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12 }} >{this.state.shippingRequest.validationComments} </Typography>
                            </Grid>
                          </>
                        }
                        {this.state.shippingRequest.status === ShippingRequestStatuses.DOCUMENTS_VALIDATED &&
                          <>
                            <Grid item xs={12} bgcolor='#0288D1' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc", marginTop: 20 }}>
                              <Typography textAlign='center' color="white" style={{ fontSize: 14 }} ><b>Validated By</b> </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12, textTransform: 'capitalize' }} >{getNameFromEmail(this.state.shippingRequest.validatedBy)} </Typography>
                            </Grid>
                            <Grid item xs={12} bgcolor='#f3f3f3' style={{ paddingTop: 5, paddingBottom: 5, border: "solid 1px #cccccc" }}>
                              <Typography textAlign='center' color="primary" style={{ fontSize: 12 }} >{formatShortDateString(this.state.shippingRequest.validatedDate, true)} </Typography>
                            </Grid>
                          </>
                        }


                      </Grid>
                    </Grid>

                  </Grid>
                </div>
                <Box className="FormFooter" >
                  <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    {/* <Button disabled={!this.state.riskAssessment.assessmentName} variant="contained" size="medium" color="info" onClick={this.props.formEditMode === FormEditMode.CREATE ? this.createRiskAssessment : this.updateRiskAssessment} ><Save /> &nbsp;{this.props.formEditMode === FormEditMode.CREATE ? "Create Assessment" : "Update Assessment"} </Button> */}
                  </Grid>
                </Box>
              </Paper>
            </div>
          </>

        }
      </GlobalVariablesContext.Consumer>
    )
  }
}

export default withRouter(CreateEditShippingRequest);
