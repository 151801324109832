import './App.css';
import React, { Component } from "react";
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { GlobalVariablesContext } from './Context';
import { Password } from '@mui/icons-material';
import { callServerAPI, LoggedOnUserTypes } from './utils';
import withRouter from './WithRouter';

/**
 * @typedef {object} Props
 * @property {import('react-router-dom').Params<string>} routerParams
 * @property {import('react-router-dom').Location} routerLocation
 * @property {import('react-router-dom').NavigateFunction} routerNavigate
 * @property {Vendor} loggedOnVendor
 * @property {LoggedOnUserTypes} loggedOnUserType
 * @extends {Component<Props>}
 */
class ChangePassword extends Component {
  /** @type { React.Context<GlobalVariables>} */
  static contextType = GlobalVariablesContext;
  /** @type React.ContextType < typeof GlobalVariablesContext >*/
  context = this.context

  constructor(props) {
    super(props);
    //declare variables to hold all function binding to class instance
    this.vendorChangePassword = this.vendorChangePassword.bind(this)
    this.updateFormValue = this.updateFormValue.bind(this)
    //set initial state
    /** @type { { oldPassword:string, newPassword:string, confirmPassword:string}} */
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  }

  componentDidMount() {

    this.context.setHeader("Agent Login Password Change")
  }

  vendorChangePassword() {
    var self = this;
    /** @type ChangePasswordDto */ let data = { oldPassword: this.state.oldPassword, newPassword: this.state.newPassword }
    self.context.showBackdrop('Updating Agent Login Password ...')
    callServerAPI('POST', '/api/AuthenticateVendor/ChangePassword', data, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        self.context.showSnackbar({ message: response.data.message, severity: 'success' })
        self.props.routerNavigate("/")
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
      .finally(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
      })
  }

  updateFormValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    this.setState({ [eventTargetName]: eventTargetValue })
  }

  render() {
    if (this.props.loggedOnUserType !== LoggedOnUserTypes.Vendor)
      return <Typography textAlign='center' variant="h6" color='primary'>This page is restricted to Clearing Agents</Typography>
    else
      return (
        <GlobalVariablesContext.Consumer>
          {(globalVariablesValues) => globalVariablesValues &&
            <>
              <Grid id="VendorChangePasswordContainer" container spacing={0} className="FormDetails" >
                <Grid item md={8} sm={6} xs={4} display='flex' alignItems='center'>
                  <img src='/logo.png' width='80%' alt="logo.png" style={{ display: 'block', margin: 'auto' }} />
                </Grid>
                <Grid item md={4} sm={6} xs={8}>
                  <Paper className="Form" style={{ width: '100%', height: 'calc(100vh - 150px)' }}>
                    <Grid id="LoginContainer" container spacing={2} className="FormDetails" alignContent='center' >
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant='h5' fontWeight={700} >Change Password </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography color="textSecondary" > Current Password </Typography>
                        <TextField fullWidth required name="oldPassword" type="password" variant="outlined" size="medium" value={this.state.oldPassword} onChange={this.updateFormValue}
                          placeholder="Enter your current password" />

                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" > New Password </Typography>
                        <TextField fullWidth required name="newPassword" type="password" variant="outlined" size="medium" value={this.state.newPassword} onChange={this.updateFormValue}
                          placeholder="Enter your new password" />
                        <Typography color="error" style={{ height: 30 }}>{this.state.newPassword && !this.state.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/) ? 'New Password must be between 8 to 20 characters and contain at least one uppercase, one lowercase and one digit' : ''}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" > Confirm Password </Typography>
                        <TextField fullWidth required name="confirmPassword" type="password" variant="outlined" size="medium" value={this.state.confirmPassword} onChange={this.updateFormValue}
                          placeholder="Confirm your new password" />
                        <Typography color="error" style={{ height: 30 }}> {this.state.newPassword !== this.state.confirmPassword && this.state.confirmPassword ? 'password confirmation is not the same as new password' : ''} </Typography>
                      </Grid>
                      <Grid item xs={12} className="FormHeaderOrFooterDetails">
                        <Button fullWidth disabled={!(this.state.oldPassword && this.state.newPassword && this.state.confirmPassword && this.state.newPassword === this.state.confirmPassword && this.state.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/))}
                          variant="contained" size="large" color="secondary" onClick={this.vendorChangePassword} ><Password /> &nbsp;Change Password</Button>
                      </Grid>

                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          }
        </GlobalVariablesContext.Consumer>
      )
  }
}

export default withRouter(ChangePassword);