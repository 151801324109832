import './App.css';
import React, { Component } from "react";
import { Button, ButtonGroup, Card, CardActionArea, CardContent, FormControlLabel, Grid, InputAdornment, MenuItem, Pagination, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { GlobalVariablesContext } from './Context';
import { Link } from 'react-router-dom';
import { Delete, Edit, Help, LibraryAdd, Preview, Search, Undo } from '@mui/icons-material';
import { callServerAPI, CheckShippingRequestEditable, CheckShippingRequestUndoable, formatShortDateString, getNameFromEmail, getShippingRequestStatusColor, getUndoTooltipForRequest, LoggedOnUserTypes, ShippingRequestStatuses, truncateTextToWhiteSpace } from './utils';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**  @readonly @enum {string} */
export const RequestGroupFilters = {
  All: 'All',
  My_Requests: 'My Requests',
  Requests_Awaiting_My_Action: 'Awaiting My Action'
}

/**
 * @typedef {object} Props
 * @property {TepngUser|null} loggedOnTepngUser
 * @property {Vendor|null} loggedOnVendor
 * @property {LoggedOnUserTypes} loggedOnUserType
 * @property {ShippingDocumentType[]} shippingDocumentTypes
 * @property {ShipmentClass[]} shipmentClasses
 * @extends {Component<Props>}
 */

export default class Home extends Component {
  /** @type { React.Context<GlobalVariables>} */
  static contextType = GlobalVariablesContext;
  /** @type React.ContextType < typeof GlobalVariablesContext >*/
  context = this.context

  constructor(props) {
    super(props);
    //declare variables to hold all function binding to class instance
    this.updateFormValue = this.updateFormValue.bind(this)
    this.updateFormNumericValue = this.updateFormNumericValue.bind(this)
    this.updateCheckBoxValue = this.updateCheckBoxValue.bind(this)
    this.onSearchBoxChange = this.onSearchBoxChange.bind(this)
    this.onSearchBoxKeyPress = this.onSearchBoxKeyPress.bind(this)
    this.onShipmentClassFilterChange = this.onShipmentClassFilterChange.bind(this)
    this.onAgentFilterChange = this.onAgentFilterChange.bind(this)
    this.onStatusFilterChange = this.onStatusFilterChange.bind(this)
    this.onDateStartFilterChange = this.onDateStartFilterChange.bind(this)
    this.onDateEndFilterChange = this.onDateEndFilterChange.bind(this)
    this.onRequestGroupFilterChange = this.onRequestGroupFilterChange.bind(this)
    this.setElementsForCurrentPage = this.setElementsForCurrentPage.bind(this)
    this.updatePagination = this.updatePagination.bind(this)
    this.pageClick = this.pageClick.bind(this)
    this.loadShippingRequests = this.loadShippingRequests.bind(this)
    this.confirmDeleteShippingRequest = this.confirmDeleteShippingRequest.bind(this)
    this.deleteShippingRequest = this.deleteShippingRequest.bind(this)
    this.confirmUndoShippingRequest = this.confirmUndoShippingRequest.bind(this)
    this.undoShippingRequest = this.undoShippingRequest.bind(this)

    //set initial state
    /** @type { {data: ShippingRequest[], filteredData: ShippingRequest[], elements: ShippingRequest[],
     * perPage:number, currentPage:number, searchFilter: string, shipmentClassFilter: number, agentFilter:string, statusFilter:string, dateStartFilter: Date, dateEndFilter: Date, requestGroupFilter:RequestGroupFilters, pageCount:number, shippingRequestIdToDelete:Number|null, shippingRequestIdToUndo:Number|null} } */
    this.state = {
      data: [],
      filteredData: [],
      elements: [],
      perPage: 16,
      currentPage: 1,
      searchFilter: "",
      shipmentClassFilter: 0,
      agentFilter: "All",
      statusFilter: "All",
      dateStartFilter: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDay()),
      dateEndFilter: new Date(),
      requestGroupFilter: RequestGroupFilters.All,
      pageCount: 0,
      shippingRequestIdToDelete: null,
      shippingRequestIdToUndo: null
    }
  }

  componentDidMount() {
    this.context.setHeader("Requests Dashboard")
    this.loadShippingRequests()
  }

  loadShippingRequests() {
    var self = this;
    self.context.showBackdrop('Loading Requests ...')
    callServerAPI('GET', '/api/Request', null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<ShippingRequest[]>> } */ response) {
        let sortedData = response.data.returnData.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1)
        self.setState({ data: sortedData });
        self.setState({ filteredData: sortedData }, self.updatePagination);
        self.context.hideBackdrop()
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.hideBackdrop()
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
  }

  filterData() {
    if (this.state.data === null) return
    this.setState({
      currentPage: 1, offset: 0,
      filteredData: this.state.data.filter(
        row =>
          (this.state.shipmentClassFilter === 0 || this.state.shipmentClassFilter === row.shipmentClassId) &&
          (this.state.agentFilter === "All" || this.state.agentFilter === row.agentCompany) &&
          (this.state.statusFilter === "All" || row.status.toUpperCase() === this.state.statusFilter.toUpperCase()) &&
          (new Date(row.createdDate) >= this.state.dateStartFilter && new Date(row.createdDate) <= this.state.dateEndFilter) &&
          (
            this.state.requestGroupFilter === RequestGroupFilters.All ||
            (this.state.requestGroupFilter === RequestGroupFilters.My_Requests && (row.createdBy === this.props.loggedOnTepngUser?.email || row.documentsSubmittedBy === this.props.loggedOnVendor?.email)) ||
            (this.state.requestGroupFilter === RequestGroupFilters.Requests_Awaiting_My_Action && this.props.loggedOnUserType === LoggedOnUserTypes.Vendor && (row.status === ShippingRequestStatuses.REQUEST_SUBMITTED || row.status === ShippingRequestStatuses.DOCUMENTS_REJECTED)) ||
            (this.state.requestGroupFilter === RequestGroupFilters.Requests_Awaiting_My_Action && this.props.loggedOnUserType === LoggedOnUserTypes.TepngUser && row.status === ShippingRequestStatuses.DOCUMENTS_SUBMITTED)
          ) &&
          (row.blawbNumber?.toUpperCase().includes(this.state.searchFilter.toUpperCase()) || row.poNumber?.toUpperCase().includes(this.state.searchFilter.toUpperCase()) || row.paarNumber?.toUpperCase().includes(this.state.searchFilter.toUpperCase()))
      )
    }, this.updatePagination)
  }

  updatePagination() {
    this.setState({ pageCount: Math.ceil(this.state.filteredData.length / this.state.perPage) }, this.setElementsForCurrentPage)
  }

  setElementsForCurrentPage() {
    const offset = (this.state.currentPage - 1) * this.state.perPage;
    let elements = this.state.filteredData.slice(offset, offset + this.state.perPage);
    this.setState({ elements: elements });
  }

  pageClick(event, selectedPage) {
    this.setState({ currentPage: selectedPage }, this.setElementsForCurrentPage);
  }

  onSearchBoxChange(event) {
    this.setState({ searchFilter: event.target.value });
  }

  onSearchBoxKeyPress(event) {
    if (event.charCode === 13)
      this.filterData()
  }

  onShipmentClassFilterChange(event) {
    this.setState({ shipmentClassFilter: Number(event.target.value) }, this.filterData);
  }

  onAgentFilterChange(event) {
    this.setState({ agentFilter: event.target.value }, this.filterData);
  }

  onStatusFilterChange(event) {
    this.setState({ statusFilter: event.target.value }, this.filterData);
  }

  onDateStartFilterChange(date) {
    this.setState({ dateStartFilter: date }, this.filterData);
  }

  onDateEndFilterChange(date) {
    this.setState({ dateEndFilter: date }, this.filterData);
  }

  onRequestGroupFilterChange(event, value) {
    this.setState({ requestGroupFilter: value }, this.filterData);
  }

  updateFormValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    this.setState({ [eventTargetName]: eventTargetValue })
  }

  updateFormNumericValue(event) {
    let eventTargetName = event.target.name; let eventTargetValue = event.target.value;
    this.setState({ [eventTargetName]: Number(eventTargetValue) })
  }

  updateCheckBoxValue(event) {
    let eventTargetName = event.target.name; let eventTargetChecked = event.target.checked;
    this.setState({ [eventTargetName]: eventTargetChecked })
  }

  /** @type { (componentName: string, date: Date) => void } */
  updateDateValue(componentName, date) {
    this.setState({ [componentName]: date })
  }

  confirmDeleteShippingRequest(event) {
    const id = Number(event.currentTarget.id)
    this.setState({ shippingRequestIdToDelete: id })
    let request = this.state.data.find(e => e.id === id)
    if (request == null) return
    this.context.showAlert({ okButtonDisplayed: true, okButtonHandler: this.deleteShippingRequest, title: "Confirm Delete Request", message: <span>Are you sure you want to delete Request with BL/AWB No: {request.blawbNumber} ?</span>, icon: <Help fontSize="large" color="secondary" /> })
  }

  deleteShippingRequest(event) {
    var self = this;
    self.context.showBackdrop('Deleting Shipping Request ...')
    callServerAPI('DELETE', '/api/Request/' + self.state.shippingRequestIdToDelete, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        self.setState({
          filteredData: self.state.filteredData.filter(inst => inst.id !== self.state.shippingRequestIdToDelete),
          data: self.state.data.filter(inst => inst.id !== self.state.shippingRequestIdToDelete),
        }, self.updatePagination);
        self.context.showSnackbar({ message: 'Deletion Successful', severity: 'success' })
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
      .finally(function () {
        self.setState({ shippingRequestIdToDelete: null })
        self.context.hideBackdrop()
      });
  }

  confirmUndoShippingRequest(event) {
    const id = Number(event.currentTarget.id)
    this.setState({ shippingRequestIdToUndo: id })
    let request = this.state.data.find(e => e.id === id)
    if (request == null) return
    this.context.showAlert({ okButtonDisplayed: true, okButtonHandler: this.undoShippingRequest, title: "Confirm " + getUndoTooltipForRequest(request.status), message: <span>Are you sure you want to {getUndoTooltipForRequest(request.status)} for Request with BL/AWB No: {request.blawbNumber} ?</span>, icon: <Help fontSize="large" color="secondary" /> })
  }

  undoShippingRequest(event) {
    let self = this;
    let api = ""
    let newStatus = ""
    let request = this.state.data.find(e => e.id === self.state.shippingRequestIdToUndo)
    if (request == null) return
    let successMessage = getUndoTooltipForRequest(request.status) + ' successful'
    self.context.showBackdrop('Undo Shipping Request ...')
    switch (request.status) {
      case ShippingRequestStatuses.REQUEST_SUBMITTED:
        newStatus = ShippingRequestStatuses.DRAFT
        api = "/api/Request/UndoSubmitRequest";
        break;
      case ShippingRequestStatuses.DOCUMENTS_SUBMITTED:
        newStatus = ShippingRequestStatuses.REQUEST_SUBMITTED
        api = "/api/Request/UndoSubmitDocuments";
        break;
      case ShippingRequestStatuses.DOCUMENTS_VALIDATED:
        newStatus = ShippingRequestStatuses.DOCUMENTS_SUBMITTED
        api = "/api/Request/UndoValidateDocuments";
        break;
      default:
        return;
    }
    callServerAPI('PATCH', api + '/' + self.state.shippingRequestIdToUndo, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        if (request == null) return
        request.status = newStatus;
        self.setState({
          filteredData: self.state.filteredData.map(inst => inst.id === self.state.shippingRequestIdToUndo ? request : inst),
          data: self.state.data.map(inst => inst.id === self.state.shippingRequestIdToUndo ? request : inst),
        }, self.updatePagination);
        self.context.showSnackbar({ message: successMessage, severity: 'success' })
      })
      .catch(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */response) {
        self.context.showSnackbar({ message: response.data.message ?? response.status + ' ' + response.statusText, severity: 'error' })
      })
      .finally(function () {
        self.setState({ shippingRequestIdToUndo: null })
        self.context.hideBackdrop()
      });
  }


  render() {
    let paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (<Pagination color="secondary" count={this.state.pageCount} siblingCount={1} boundaryCount={1}
        onChange={this.pageClick} page={this.state.currentPage} size="small" classes={{ ul: "PaginationUl" }} />)
    }
    return (
      <GlobalVariablesContext.Consumer>
        {(globalVariablesValues) => globalVariablesValues && this.state.data &&
          <>
            <div style={{ backgroundColor: "#eeeeee", padding: 10 }} >
              <Grid container justifyContent="start" alignItems="center" spacing={2} >
                <Grid item xs={12} lg={3} >
                  <TextField fullWidth select variant="standard" size="small" name="agentFilter" value={this.state.agentFilter} onChange={this.onAgentFilterChange}>
                    <MenuItem value="All">All Agents</MenuItem >
                    {[...new Set(this.state.data.map(e => e.agentCompany))].sort().map(agent => <MenuItem key={agent} value={agent}>{agent}</MenuItem >)}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={3} >
                  <TextField fullWidth select variant="standard" size="small" name="shipmentClassFilter" value={this.state.shipmentClassFilter} onChange={this.onShipmentClassFilterChange}>
                    <MenuItem value="0">All Shipment Classes</MenuItem >
                    {this.props.shipmentClasses.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={3} >
                  <TextField fullWidth select variant="standard" size="small" name="statusFilter" value={this.state.statusFilter} onChange={this.onStatusFilterChange}>
                    <MenuItem value="All">All Status</MenuItem >
                    <MenuItem value={ShippingRequestStatuses.DRAFT}>{ShippingRequestStatuses.DRAFT}</MenuItem >
                    <MenuItem value={ShippingRequestStatuses.REQUEST_SUBMITTED}>{ShippingRequestStatuses.REQUEST_SUBMITTED}</MenuItem >
                    <MenuItem value={ShippingRequestStatuses.DOCUMENTS_SUBMITTED}>{ShippingRequestStatuses.DOCUMENTS_SUBMITTED}</MenuItem >
                    <MenuItem value={ShippingRequestStatuses.DOCUMENTS_VALIDATED}>{ShippingRequestStatuses.DOCUMENTS_VALIDATED}</MenuItem >
                    <MenuItem value={ShippingRequestStatuses.DOCUMENTS_REJECTED}>{ShippingRequestStatuses.DOCUMENTS_REJECTED}</MenuItem >
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={3} >
                  <TextField fullWidth color="primary" title="Search by PO, PAAR or BL/AWB" variant="standard" id="txtsearch" placeholder="Search PO, PAAR or BL/AWB" size="small" classes={{ root: "SearchBox" }}
                    InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }}
                    value={this.state.searchFilter} onChange={this.onSearchBoxChange} onKeyPress={this.onSearchBoxKeyPress} />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <RadioGroup row name="requestGroupFilter" value={this.state.requestGroupFilter} onChange={this.onRequestGroupFilterChange}>
                    <FormControlLabel style={{ color: "#000000" }} key={RequestGroupFilters.All} value={RequestGroupFilters.All} control={<Radio color="error" />} label={RequestGroupFilters.All} />
                    <FormControlLabel style={{ color: "#000000" }} key={RequestGroupFilters.My_Requests} value={RequestGroupFilters.My_Requests} control={<Radio color="error" />} label={RequestGroupFilters.My_Requests} />
                    <FormControlLabel style={{ color: "#000000" }} key={RequestGroupFilters.Requests_Awaiting_My_Action} value={RequestGroupFilters.Requests_Awaiting_My_Action} control={<Radio color="error" />} label={RequestGroupFilters.Requests_Awaiting_My_Action} />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label="Start Date" inputFormat="dd/MMM/yyyy" value={this.state.dateStartFilter} onChange={this.onDateStartFilterChange} renderInput={(params) => <TextField {...params} size="small" fullWidth />} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label="End Date" inputFormat="dd/MMM/yyyy" value={this.state.dateEndFilter} onChange={this.onDateEndFilterChange} renderInput={(params) => <TextField {...params} size="small" fullWidth />} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={11} lg={3} >
                  {paginationElement}
                </Grid>
                <Grid item xs={1} lg={1} justifyContent='end' display='flex'>
                  <Button color="info" size='large' title="Create New Request"> <Link to={'/create-shippingrequest'} style={{ color: 'red' }}><LibraryAdd fontSize='large' /></Link> </Button>
                </Grid>
              </Grid>
            </div>
            <Grid id="ApplicationCardsContainer" container spacing={3} style={{ padding: 20 }} >
              {this.state.elements.map(row =>
                <Grid key={row.id} item xs={12} sm={6} md={4} lg={3} >
                  <Card style={{ padding: '10px', color: "secondary", height: '265px', flex: 1 /* tells the elements to spray evenly so no need for width or minWidth */ }} >
                    <CardActionArea>
                      <div className="ribbon"><span style={{ background: `linear-gradient(${getShippingRequestStatusColor(row.status)} 0%,${getShippingRequestStatusColor(row.status)} 100%)` }}>{row.status}</span> </div>
                      <CardContent classes={{ root: "CardContentRoot" }} >
                        <Typography style={{ fontSize: 12, height: 25, overflowY: 'hidden' }} title="Agent" color="red" > {row.agentCompany ? row.agentCompany : "No Agent Specified"} </Typography>
                        <Typography color='primary' style={{ fontSize: 12, lineHeight: '25px', height: 25, overflowY: 'hidden' }} > BL / AWB No: <Typography title={row.blawbNumber} component="span" color='textSecondary'>{row.blawbNumber}</Typography> </Typography>
                        <Typography color='primary' style={{ fontSize: 12, lineHeight: '25px', height: 25, overflowY: 'hidden' }} > Class: <Typography component="span" color='primary'>{this.props.shipmentClasses.find(e => e.id === row.shipmentClassId)?.name}</Typography> </Typography>
                        <Typography color='primary' style={{ fontSize: 12, lineHeight: '25px', height: 25, overflowY: 'hidden' }} > PO Number: <Typography title={row.poNumber} component="span" color='textSecondary'>{row.poNumber}</Typography> </Typography>
                        <Typography color='primary' style={{ fontSize: 12, lineHeight: '25px', height: 25, overflowY: 'hidden' }} > Requested By: <Typography title={row.createdBy} component="span" color='primary' style={{ textTransform: 'capitalize' }}>{getNameFromEmail(row.createdBy)}</Typography> </Typography>
                        <Typography color='primary' style={{ fontSize: 12, lineHeight: '25px', height: 25, overflowY: 'hidden' }} > Requested Date: <Typography component="span" color='primary'>{formatShortDateString(row.createdDate)}</Typography> </Typography>
                        <hr />
                        <Tooltip arrow title={<Typography style={{ fontSize: 12, color: "secondary", }}>{row.title} </Typography>} >
                          <Typography color="textSecondary" style={{ fontSize: 12, lineHeight: '25px', height: 50, overflowY: 'hidden' }} component="p"> {truncateTextToWhiteSpace(row.title, 130)} </Typography>
                        </Tooltip>
                      </CardContent>
                    </CardActionArea>
                    <div style={{ display: 'flex', paddingTop: 5, paddingBottom: 5, justifyContent: 'end' }} >
                      <ButtonGroup size="small" variant="text" color="primary"  >
                        <Button color="error" id={row.id + ''} title="View"> <Link to={`/view-shippingrequest/${row.id}`} style={{ color: 'red' }}><Preview /></Link>  </Button>
                        <Button color="error" id={row.id + ''} title="Edit" disabled={!CheckShippingRequestEditable(row, this.props.loggedOnUserType)}> <Link to={`/edit-shippingrequest/${row.id}`} style={{ color: CheckShippingRequestEditable(row, this.props.loggedOnUserType) ? 'red' : '#bbbbbb' }} ><Edit /></Link>  </Button>
                        <Button color="error" id={row.id + ''} onClick={this.confirmDeleteShippingRequest} title="Delete" disabled={row.status !== ShippingRequestStatuses.DRAFT || this.props.loggedOnUserType !== LoggedOnUserTypes.TepngUser}> <Delete /></Button>
                        <Button color="error" id={row.id + ''} onClick={this.confirmUndoShippingRequest} title={getUndoTooltipForRequest(row.status)} disabled={!CheckShippingRequestUndoable(row, this.props.loggedOnUserType)}> <Undo /></Button>
                      </ButtonGroup>
                    </div>
                  </Card>
                </Grid>
              )}
            </Grid>
          </>
        }
      </GlobalVariablesContext.Consumer>
    )
  }
}
